.auth_and_reg {
  @include flex(lt);
  align-items: stretch;
  min-height: 100vh;
  // height: 100%;

  a {
    color: #4a9eea;
    &:hover {
      color: #007eff;
      text-decoration: none;
    }
  }

  &-left {
    @include flex(st);
    @include flexcol(n);
    max-width: 460px;
    position: relative;
    background-color: #f9f9fa;
    @include nb2 {
      max-width: 300px;
    }
    @include sm {
      display: none;
    }
    &-top {
      padding: 60px 30px;
    }
    &-bottom {
      width: 100%;
    }
    &-logo {
      @include flex(lc);
      // flex-wrap: wrap;
      // margin-right: 50px;
      font-family: Montserrat;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 22px;
      letter-spacing: 0.1px;
      cursor: pointer;
      color: #25282b;
      &:hover {
        color: #25282b;
        text-decoration: none;
      }
      @include lg {
        margin-right: 25px;
      }
      &_img {
        width: 55px;
        margin-right: 10px;
        img {
          width: 100%;
          height: auto;
        }
      }
      &_text {
        color:  #007eff;
        // width: 80px;
      }
    }
    &-text {
      margin-top: 20px;
      font-family: Montserrat;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 124.5%;
      letter-spacing: 0.2px;
      color: #cccccc;
      @include xl {
        font-size: 18px;
      }
      @include vg {
        font-size: 16px;
      }
      @include md {
        font-size: 14px;
      }
      @include sm {
        display: none;
      }
    }
    &-bottom_animate_img {
      width: 100%;
    }
  }
  &-right {
    // min-height: 100vh;
    flex-grow: 1;
    padding: 60px 25px;
    &-top {
      @include flex(sc);
      @include sm {
        background-color: #f9f9fa;
        padding: 15px 20px;
      }
    }
    &-bottom {
      @include sm {
        padding: 25px;
      }
    }
    @include sm {
      min-height: auto;
      width: 100%;
      padding: 0;
    }
    
    &_back {
      display: inline-block;
      position: relative;
      padding-left: 30px;
      cursor: pointer;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: 0.2px;
      color: #4a9eea;

      &::before {
        content: "";
        width: 20px;
        height: 20px;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        position: absolute;
        background-repeat: no-repeat;
        background-size: 20px 20px;
        background-image: url("../../../../assets/images/arrowRightBlue.png");
      }
    }
    &_logo {
      display: none;
      position: relative;
      padding-left: 50px;
      text-decoration: none;
      @include sm {
        display: block;
      }
      @include ph {
        padding-left: 35px;
      }
      &:hover {
        text-decoration: none;
      }
      &::before {
        position: absolute;
        content: "";
        width: 35px;
        height: 50px;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        background-repeat: no-repeat;
        background-size: 35px 50px;
        background-image: url("../../../../assets/images/logoGreen.png");

        @include ph {
          width: 25px;
          height: 40px;
          background-size: 25px 40px;
        }
      }
      
      &-text {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        line-height: 22px;
        letter-spacing: 0.1px;
        font-size: 16px;
        line-height: 15px;
        letter-spacing: 0.2px;
        text-transform: uppercase;
        color: #25282b;

        @include ph {
          font-size: 14px;
        }
      }
    }
    &_form {
      width: 100%;
      max-width: 440px;
      margin: 0 auto;
      margin-top: 200px;

      &_reg {
        margin-top: 100px;
      }

      @include sm {
        margin-top: 100px;
      }

      @include ph {
        margin-top: 45px;
      }

      input::placeholder {
        color: #CACCCF;
      }

      &-input {
        position: relative;
      }

      &-help_text {
        position: relative;
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 24px;
        padding-left: 10px;
        align-items: center;
        letter-spacing: 0.1px;
        color: #ff6262;
        &.active {
          &::before {
            content: "";
            border-radius: 30px;
            position: absolute;
            width: 4px;
            height: 4px;
            left: 2px;
            margin-top: 10px;
            background: #ff6262;
            transform: matrix(-1, 0, 0, 1, 0, 0);
          }
        }
      }
      &-heder {
        position: relative;
        width: 100%;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 35px;
        line-height: 54px;
        text-align: center;
        color: #25282b;
        @include vg {
          font-size: 27px;
        }
      }
      &-label {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.2px;
        color: #25282b;
        &_wrap {
          @include flex(sc);
        }
      }
      &-input_style {
        position: relative;
        color: #495057 !important;
        background: #f3f3f4 !important;
        border: 2px solid rgba(37, 40, 43, 0.02) !important;
        box-sizing: border-box;
        border-radius: 4px !important;
        &:focus {
          color: #495057 !important;
          background-color: #fff !important;
          border-color: #4A9EEA !important;
          outline: 0 !important;
          box-shadow: 0 0 0 0.2rem rgba(229, 242, 255, 0.8) !important;
        }
      }
      &-sign_in_btn {
        position: relative;
        width: 100%;
        height: 45px;
        font-style: normal;
        font-weight: 600 !important;
        font-size: 14px;
        line-height: 16px;
        text-align: center;
        letter-spacing: 1.25px;
        border-color: #4a9eea !important;
        background-color: #4a9eea !important;
        border-radius: 4px;
        color: #ffffff;
        font-family: Montserrat;
        transition: .3s;
        &:hover {
          background-color: #007eff !important;
        }
      }
      &-group_password {
        position: relative;
        // height: 40px;
      }
      &-forgot_password {
        &_top,
        &_bottom {
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 24px;
          letter-spacing: 0.1px;
          color: #4a9eea;

          &:hover {
            color: #007eff;
            text-decoration: none;
          }
        }
        &_bottom {
          display: none;
          margin-top: 8px;
          @include ph {
            display: inline-block;
          }
          &_text {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 24px;
            display: flex;
            align-items: center;
            text-align: center;
            letter-spacing: 0.1px;
            color: #52575c;
            margin-top: 8px;
            span {
              text-align: center;
            }
          }
        }
        &_top {
          @include ph {
            display: none;
          }
        }
      }
      &-btn_view_pass,
      &-btn_view_pass_registration  {
        position: absolute;
        background: none !important;
        border: none !important;
        width: 5px;
        height: 15px;
        top: 50%;
        transform: translateY(-50%);
        right: 12px;
        z-index: 2 !important;
        &.active {
          background-repeat: no-repeat !important;
          background-size: 82% !important;
          background-image: url("../../../../assets/images/eyeVisible.png") !important;
        }
        &.deactive {
          background-repeat: no-repeat !important;
          background-size: 82% !important;
          background-image: url("../../../../assets/images/eyeHidden.png") !important;
        }
        &:focus {
          box-shadow: none !important;
          outline: none !important;
        }
      }
      
      &-terms_use {
        position: relative;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: 0.1px;
        color: #52575c;
        padding: 15px 0;
        border-bottom: 1px solid #cacccf;
      }
      &-terms_use_link {
        position: relative;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.1px;
        color: #4a9eea;
        right: 0;
        
        &-last_el {
          margin-bottom: 0.5rem;
        }
      }

      &-registrations {
        &_link {
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 24px;
          letter-spacing: 0.1px;
          color: #4a9eea;
          &:hover {
            color: #007eff;
            text-decoration: none;
          }
        }
        &_text {
          margin-top: 21px;
          text-align: center;
        }
      }
    }
  }
}