@mixin inputCustom {
  width: calc(100% - 60px);
  position: relative;
  color: #495057;
  background: #f3f3f4;
  box-sizing: border-box;
  border-radius: 4px;
  border: none;
  box-shadow: 0 0 0 0.2rem rgb(255, 255, 255);
  &:hover {
    color: #495057;
    background-color: #fff;
    // border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    border: none;
  }
  &:active {
    box-shadow: none;
    border: none;
  }
}
@mixin h4CustomTitle {
  padding-left: 30px;
  font-family: $fontMonserat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
  letter-spacing: 0.2px;
  color: #25282b;
}
@mixin titleForModalCreateSubares {
  margin-top: 20px;
  font-family: $fontRoboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.2px;
  display: inline-block;
}
@mixin paramsWrapperForPFML {
  width: 363px;
  height: 80px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-sizing: border-box;
  border-radius: 2px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  .wrapper__input {
    margin: 0;
  }
  &-text_info {
    display: flex;
    align-items: center;
    width: 45%;
    p {
      margin: 0;
      margin-right: 5px;
      font-family: $fontRoboto;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.2px;
      color: #e8e8e8;
    }
  }
  &-input {
    width: 106px;
    height: 20px;
  }
}

.modal {
  &-wrap {
    top: 42% !important;
    left: -5% !important;
    &-backdrop {
      background: #001a34 !important;
      opacity: 0.7 !important;
    }
    .modal {
      &-content {
        width: 406px;
        height: 190px;
        background: #37393a;
        box-shadow: 12px 12px 24px rgba(35, 111, 153, 0.04),
          -12px -12px 24px rgba(35, 111, 153, 0.04);
        border-radius: 4px;
      }
    }
  }
  &-close_btn {
    position: absolute;
    opacity: 1;
    top: 12px;
    right: 12px;
    width: 20px;
    height: 20px;
    cursor: pointer;
    background-image: url("../../../../assets/images/cansel.svg");
  }
  &-icon {
    position: absolute;
    width: 50px;
    height: 50px;
    top: 45px;
    left: 30px;
  }
  &-text_info_block {
    position: absolute;
    width: 275px;
    height: 60px;
    top: 50px;
    right: 30px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 140%;
    align-items: center;
    letter-spacing: 0.2px;
    color: #ffffff;
  }
  &-confirm_component_wrap {
    position: absolute;
    bottom: 30px;
    left: 65px;
  }
  &-btn_success {
    background: #4a9eea;
    border-radius: 4px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    align-items: center;
    text-align: center;
    letter-spacing: 1.25px;
    color: #ffffff;
    border: none;
    position: absolute;
    width: 133px;
    height: 40px;
    top: 120px;
    right: 30px;
  }
  &-load {
    &-text {
      text-align: center;
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      letter-spacing: 0.2px;
      color: #ffffff;
    }
  }
}
.wrapper-modal-setting {
  background: #001a34;
  opacity: 0.7;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 70;
  font-family: $fontMonserat;
  & h4 {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
  }
}
.modal-setting {
  position: fixed;
  top: 30%;
  left: 35%;
  opacity: 1;
  background: $w;
  padding: 30px 40px;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  color: #25282b;
  z-index: 90;
  width: 30em;
  @include md {
    left: 10%;
  }
  &_form-close {
    width: 20px;
    cursor: pointer;
    display: inline-block;
    position: relative;
    // top: -1vh;
    left: 95%;
    height: 20px;
    opacity: 1;
    background-repeat: no-repeat;
    background-image: url("../../../../assets/images/closeModalWhite.svg");
  }
  &-title {
    font-family: MontserratRegular;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    letter-spacing: 0.2px;
  }
  & .wrapper-button-group {
    padding: 20px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    & button {
      height: 40px;
    }
    & p {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      width: 100%;
      color: #4a9eea;
      margin: 0;
      cursor: pointer;
    }
  }
}
.modal-out {
  color: $Government;
  cursor: pointer;
}
.custom_size {
  &-for {
    &-modal_perosnal_page {
      width: 480px;
      height: 208px;
      background: #ffffff;
      box-shadow: -12px -12px 24px rgba(35, 111, 153, 0.04),
        12px 12px 24px rgba(35, 111, 153, 0.04);
      border-radius: 4px;
    }
  }
}
.modal {
  &-change_name {
    &-wrapper {
      padding: 12px;
      width: 406px;
      height: 207px;
      &-wrapper_fields {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      h4 {
        @include h4CustomTitle;
      }
      .input_form {
        @include inputCustom;
      }
    }
  }
  &-change_password {
    &-wrapper {
      padding: 12px;
      width: 406px;
      height: 267px;
      h4 {
        @include h4CustomTitle;
      }
      &-wrapper_fields {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        &-wrapper_custom_field {
          position: relative;
          width: calc(100% - 60px);
          &-eye {
            position: absolute;
            width: 22px;
            height: 19px;
            top: 33%;
            right: 4%;
            cursor: pointer;
            z-index: 99;
            &.hidden_pass {
              background-repeat: no-repeat;
              display: inline-block;
              background-size: 100%;
              background-image: url("../../../../assets/images/eyeHidden.png");
            }
            &.visible_pass {
              background-repeat: no-repeat;
              display: inline-block;
              top: 36%;
              background-size: 100%;
              background-image: url("../../../../assets/images/eyeVisible.png");
            }
          }
        }
        &-password_custom_field {
          width: 100% !important;
        }
      }
      .input_form {
        @include inputCustom;
      }
      .wrapper-button-group {
        padding-left: 34px;
        padding-right: 34px;
        padding-top: 5px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        span {
          display: inline-block;
          font-family: $fontMonserat;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0.1px;
          color: #4a9eea;
          cursor: pointer;
        }
        button {
          width: 133px;
          height: 40px;
        }
      }
    }
    .modal-setting_form-close {
      background-repeat: no-repeat;
      background-size: 100%;
      background-image: url("../../../../assets/images/closeModalWhite.svg");
    }
  }
}

.modal-default {
  font-family: Montserrat;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: #25282b;
  .modal {
    &-dialog {
      position: absolute;
      margin: 25px auto;
      background-color: #fff;
      width: 100%;
      max-width: 500px;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      border-radius: 16px;
      box-shadow: -12px -12px 24px rgba(35, 111, 153, 0.04),
        12px 12px 24px rgba(35, 111, 153, 0.04);
      @include sm {
        top: 0;
        transform: translateX(-50%);
      }
      @include xs {
        margin: 0 auto;
        max-width: 100%;
        height: 100%;
        border-radius: 0;
      }
    }
    &-content {
      position: relative;
      padding: 30px;
      border: none;
      @include xs {
        padding: 15px;
      }
    }
    &-header,
    &-body,
    &-footer {
      padding: 0;
    }
    &-header {
      border: none;
      align-items: center;
    }
    &-title {
      font-weight: 600;
      font-size: 32px;
      line-height: 42px;
      letter-spacing: 0.1px;
      @include xs {
        font-size: 24px;
      }
    }
    &-footer {
      border: none;
    }
  }
  &__backdrop {
    background-color: rgba(0, 0, 0, 0.15);
  }
  &__image {
    max-width: 50px;
  }
  &__img {
    width: 100%;
  }
  &__close {
    @include xs {
      max-width: 45px;
    }
  }
  &__close {
    position: absolute;
    width: 25px;
    height: 25px;
    @include flex(cc);
    right: -40px;
    top: 0;
    transform: translateY(-50%);
    cursor: pointer;
    img {
      width: 100%;
      height: auto;
    }
    @include sm {
      top: 15px;
      right: 15px;
      transform: none;
      border-radius: 50%;
      background-color: rgba(0, 0, 0, 0.15);
      padding: 7px;
    }
  }
  &__header {
    @include flexcol(n);
  }
  &__title {
    margin-top: 20px;
    margin-bottom: 30px !important;
    @include xs {
      margin-top: 10px;
      margin-bottom: 15px !important;
    }
  }
  &__subtitle {
    margin-bottom: 40px;
    font-family: Ubuntu;
    font-weight: 300;
    font-size: 20px;
    line-height: 30px;
    color: #16324f;
    opacity: 0.5;
    @include xs {
      font-size: 16px;
      margin-bottom: 25px;
    }
  }
  &__btn {
    padding: 15px 26px;
    border: 1px solid #007eff;
    border-radius: 8px;
    outline: none;
    font-weight: 600;
    letter-spacing: 0.5px;
    color: #fdfdff;
    background: #007eff;
    transition: 0.3s;
    @include xs {
      padding: 10px 20px;
    }
    &:focus {
      outline: none;
    }
    &:hover {
      background: lighten(#007eff, 3%);
    }
  }
  &__form {
    &-label {
      width: 100%;
      margin-bottom: 23px;
      @include xs {
        margin-bottom: 15px;
      }
      &-span {
        display: block;
        margin-bottom: 8px;
        @include xs {
          font-size: 14px;
        }
      }
    }
    &-input,
    &-textarea {
      padding: 12px;
      width: 100%;
      background: #f3f3f4;
      border: 2px solid rgba(37, 40, 43, 0.02);
      border-radius: 4px;
      transition: 0.3s;
      @include xs {
        padding: 8px;
        font-size: 14px;
      }
      &::placeholder {
        color: #cacccf;
      }
      &:focus {
        background: #fff;
        border: 2px solid #007eff;
        box-shadow: 0 0 0 3px #e5f2ff;
      }
    }
    &-textarea {
      resize: none;
      height: 124px;
    }
  }
}
.modal {
  &-xl_table {
    &-back_side {
      position: fixed;
      width: 100%;
      height: 100%;
      background: #001a34;
      opacity: 0.7;
      z-index: 98;
    }
    &-wrapper {
      position: fixed;
      width: 90%;
      height: auto;
      left: 5%;
      top: 30%;
      min-width: 300px;
      min-height: 200px;
      z-index: 99;
      background: #37393a;
      box-shadow: -12px -12px 24px rgba(35, 111, 153, 0.04),
        12px 12px 24px rgba(35, 111, 153, 0.04);
      border-radius: 16px;
      &-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
      &-title {
        font-family: $fontRoboto;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.2px;
        color: #e8e8e8;
        padding-left: 40px;
        padding-top: 40px;
      }
      &-close_btn {
        position: relative;
        margin-top: 17px;
        margin-right: 17px;
        display: block;
        width: 20px;
        height: 20px;
        background-size: 100%;
        cursor: pointer;
        background-repeat: no-repeat;
        background-image: url("../../../../assets/images/closeModalWhite.svg");
      }
      &-content {
      }
    }
  }
}

.modal {
  &-params_subareas {
    &-position_center {
      display: flex;
      position: absolute;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
    }
    &-back_side {
      position: fixed;
      width: 100%;
      height: 100%;
      background: #001a34;
      opacity: 0.7;
      z-index: 98;
    }
    &-wrapper {
      position: fixed;
      z-index: 99;
      background: #37393a;
      box-shadow: -12px -12px 24px rgba(35, 111, 153, 0.04),
        12px 12px 24px rgba(35, 111, 153, 0.04);
      border-radius: 16px;
      overflow: hidden;
      &-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        background: #25282b;
        height: 30px;
      }
      &-title {
        font-family: $fontMonserat;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 31px;
        letter-spacing: 0.2px;
        color: #ffffff;
        padding-left: 18px;
      }
      &-close_btn {
        position: relative;
        margin-top: 11px;
        margin-right: 17px;
        display: block;
        width: 9px;
        height: 9px;
        background-size: 100%;
        cursor: pointer;
        background-repeat: no-repeat;
        background-image: url("../../../../assets/images/closeVector.svg");
      }
      &-content {
        width: 100%;
        height: calc(100% - 30px);
        padding: 20px;
        &-section {
          width: 100%;
          height: auto;
          border-bottom: 2px solid #424242;
          font-family: $fontRoboto;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;
          letter-spacing: 0.2px;
          color: #e8e8e8;
          display: flex;
          &-subareas_params {
            width: 363px;
            display: inline-block;
            &-wrapper_input {
              display: flex;
              // justify-content: space-between;
              .wrapper__input-wrapper_add_btn {
                width: auto;
                .wrapper__input-input {
                  top: 0px;
                  height: 100%;
                  button {
                    padding-left: 7px;
                  }
                }
              }
            }
            &-nubmer_subareas {
              font-family: $fontRoboto;
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 16px;
              letter-spacing: 0.2px;
              color: #e8e8e8;
              top: 3px;
              position: relative;
            }
            &-input {
              width: 106px;
              height: 26px;
              margin-left: 10px;
            }
            &-title_params {
              @include titleForModalCreateSubares;
            }
            &-params_wrap {
              @include paramsWrapperForPFML;
            }
          }
          &-subareas_creator {
            &-wrapper {
              display: inline-block;
              position: relative;
              // top: -60px;
            }
            display: flex;
            flex-direction: column;
            &-btn_wrapp {
              width: 177px;
              height: 80px;
              box-shadow: -12px -12px 24px rgba(35, 111, 153, 0.04),
                12px 12px 24px rgba(35, 111, 153, 0.04);
              margin-left: 20px;
              margin-bottom: 10px;
            }
            &-btn_create_subareas {
              width: 177px !important;
              height: 80px !important;
              background: #535353 !important;
              box-shadow: 2px 2px 4px #474747, -2px -2px 4px #5f5f5f !important;
              border-radius: 1px !important;
              font-family: $fontRobotoCondensed !important;
              font-style: normal;
              font-weight: bold;
              font-size: 14px !important;
              line-height: 16px !important;
              text-align: center;
              letter-spacing: 0.2px !important;
              color: #cacccf !important;
              margin: 0 !important;
            }
          }
          &-triangulation_grooves {
            width: 363px;
            &-title {
              @include titleForModalCreateSubares;
              display: block;
            }
            &-select_type_env {
              @include selectFromModal;
              margin-top: 10px;
              width: 276px;
              margin-left: 10px;
            }
            &-select_params_name {
              @include selectFromModal;
              margin-top: 10px;
              width: 170px;
              margin-left: 10px;
            }
            &-params_wrap {
              @include paramsWrapperForPFML;
              width: 363px;
            }
          }
          &-voltage_params {
            display: flex;
            justify-content: space-between;
            &-all_params {
              width: 363px;
              margin-top: 20px;
              margin-bottom: 20px;
              &-fields {
                display: flex;
                justify-content: space-between;
                height: 26px;
                margin-bottom: 10px;
                font-family: $fontRoboto;
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 16px;
                letter-spacing: 0.2px;
                color: $colorForSidebarText;
                span {
                  padding-top: 10px;
                }
              }
              &-input {
                width: 106px;
                height: 26px;
                &:disabled {
                  color: rgba(232, 232, 232, 0.3);
                }
              }
            }
            &-source_pole {
              margin-left: 20px;
              &-title {
                @include titleForModalCreateSubares;
                margin-top: 25px;
              }
              &-wrapper_radio {
                border: 1px solid rgba(255, 255, 255, 0.2);
                box-sizing: border-box;
                border-radius: 2px;
                padding: 5px;
                height: 55px;
                label {
                  margin-bottom: 0;
                  top: -4px;
                  position: relative;
                  margin-left: 5px;
                }
                .default_components-radio_btn {
                  // margin-bottom: 5px;
                }
              }
            }
          }
        }
        &-btn_confirm_component {
          margin-top: 20px;
          display: flex;
          justify-content: center;
        }
      }
    }
  }
}

.modal_pfml {
  &-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    z-index: 200;
  }
  &-content {
    position: absolute;
    width: 406px;
    height: 190px;
    background: #37393a;
    box-shadow: 12px 12px 24px rgba(35, 111, 153, 0.04), -12px -12px 24px rgba(35, 111, 153, 0.04);
    border-radius: 4px;
    display: block;
    // left: calc(50% - 150px);
    border-radius: 5px;
    padding: 20px;
    z-index: 201;
  }
}
.modal_loader {
  &-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    z-index: 200;
  }
  &-content {
    position: absolute;
    width: 300px;
    height: 200px;
    display: block;
    // left: calc(50% - 150px); background: #37393a;
    background: #37393a;
    box-shadow: 12px 12px 24px rgba(35, 111, 153, 0.04), -12px -12px 24px rgba(35, 111, 153, 0.04);
    padding: 20px;
    z-index: 201;
  }
  &-title {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    text-align: center;
    letter-spacing: 0.2px;
    color: #fff;
    position: relative;
  }
  &-spiner {
    height: 120px !important;
  }
  &-progress {
    text-align: center;
  }
}
