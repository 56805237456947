.sidebar {
  & p {
    font-family: $fontRoboto;
  }
  box-shadow: 4px 1px 21px #d8d8d8;
  min-width: 300px;
  max-width: 300px !important;
  overflow-y: auto;
  overflow-x: hidden;
  // min-height: 100vh;
  height: 100%;
  color: #e8e8e8;
  &-pfml__col_pading {
    padding: 0 !important;
  }
  /* width */
  &::-webkit-scrollbar {
    width: 5px !important;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1 !important;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #d8d8d8 !important;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555 !important;
  }
}

////
input:active,
input:focus,
button:active,
button:focus {
  outline: none;
}

// ChooseCurrent
.wrapper-current {
  width: 280px;
  margin: 0 auto;
  border-left: 1px solid $borderForSidebar;
  border-top: 1px solid $borderForSidebar;
  border-right: 1px solid $borderForSidebar;
  border-radius: 2px 2px 0px 0px;
}
.current {
  margin: 7px;
  color: $colorForSidebarText;
  font-size: 14px;
  text-align: start;
  font-family: $fontRoboto;
}

.sidebar__choose-current {
  border-top: 1px solid $borderForSidebar;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.2px;
  display: flex;
}
.sidebar__choose-current__connection__group {
  & label {
    display: flex;
    align-items: center;
    color: $colorForSidebarText;
  }
}
.sidebar__choose-current__choose-kind {
  display: flex;
  justify-content: space-evenly;
}
.sidebar__choose-current__options:nth-child(1) {
  border-right: 1px solid $borderForSidebar;
}
.sidebar__choose-current__options {
  width: 150px;
  padding-left: 10px;
}
.wrapper-current-toggle {
  width: 283px;
  margin: 0 auto;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.2px;
  box-sizing: border-box;
  border-radius: 2px;
  & h5 {
    color: $colorForSidebarText;
    padding: 5px 0;
    font-size: 14px;
  }
}

//InputValueData
.wrapper__input {
  margin-bottom: 2px;
  display: flex;
  align-items: center;
  justify-content: space-between;
 
  & label {
    display: block;
    color: $colorForSidebarText;
    font-family: $fontRoboto;
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 0;
  }
  &-wrapper_add_btn {
    position: relative;
    width: 54px;
    display: inline-block;
  }
  &-input {
    position: absolute;
    display: flex;
    justify-content: center;
    flex-flow: column;
    background: #424242;
    border: 1px solid rgba(255, 255, 255, 0.15);
    box-sizing: border-box;
    width: 14px;
    height: 20px;
    overflow: hidden;
    top: 2px;
    right: 0;
    button {
      border: none;
      &:focus {
        outline: none;
      }
    }
    &-inc_btn {
      position: relative;
      display: inline-block;
      width: 10px;
      height: 10px;
      background-color: transparent;
      background-repeat: no-repeat;
      background-size: 70%;
      background-position: center;
      background-image: url("../../../../assets/images/arrowForInputNumber.svg");
    }
    &-dec_btn {
      position: relative;
      display: inline-block;
      width: 10px;
      height: 10px;
      background-color: transparent;
      background-repeat: no-repeat;
      background-size: 70%;
      background-position: center;
      background-image: url("../../../../assets/images/arrowForInputNumberDown.svg");
    }
  }
}
.wrapper__input-wrapper_add_btn {
  .input__data {
    -webkit-appearance: none;
    -moz-appearance: textfield;
    // appearance: none;
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      -moz-appearance: textfield;
      // appearance: none;
      // margin: 0;
    }
  }
}
.input__data {
  font-family: $fontRobotoCondensed;
  background: $darkInput;
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-sizing: border-box;
  border-radius: 1px;
  color: $w;
  // padding-left: 5px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  input {
    padding-left: 5px;
  }
  &:focus {
    border: 1px solid $BlueEmpty;
    background: #16191d;
  }
}
.input_form {
  width: 100%;
  min-height: 40px;
  background: #f3f3f4;
  border: 2px solid rgba(37, 40, 43, 0.02);
  box-sizing: border-box;
  border-radius: 4px;
  box-sizing: border-box;
  padding-left: 14px;
  margin: 10px 0;
  &:focus {
    border: 2px solid $BlueEmpty;
  }
}
//Modal General Data

.sidebar-modal {
  color: $colorForSidebarText;
  margin: 0 auto;
  width: 280px;
  border-top: 1px solid #353434;
  border-bottom: 1px solid #353434;
  padding: 10px 0;
}
.sidebar-modal__drop-modal {
  height: 36px;
  padding-left: 15px;
  display: flex;
  align-items: center;
  cursor: pointer;
  &::after {
    content: "";
    position: relative;
    right: -110px;
    top: -5px;
    cursor: pointer;
    padding: 10px;
    background-image: url("../../../../assets/images/arrowDownSidebar.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
  }
}
.sidebar-modal__drop-modal-active {
  height: 36px;
  padding-left: 15px;
  display: flex;
  align-items: center;
  cursor: pointer;
  & .sidebar-modal__drop-modal__all-data {
    color: $Government !important;
  }
  &::after {
    content: "";
    position: relative;
    right: -110px;
    top: -5px;
    cursor: pointer;
    padding: 10px;
    background-image: url("../../../../assets/images/sidebarWhiteArrow.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
  }
}
.sidebar-modal__drop-modal__all-data {
  text-align: center;
  font-family: $fontRoboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
}
.sidebar-modal__select {
  font-size: 12px;
  line-height: 16px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 2px;
  box-sizing: border-box;
  padding: 5px;
}
.sidebar-modal__select-none {
  position: absolute;
  top: -20%;
  width: 0;
  overflow: hidden;
}
//parameters device value
.sidebar__options {
  margin: 5px;
  padding: 5px;
  border: 1px solid #787575;
  box-sizing: border-box;
  border-radius: 2px;

  &__options-device .input__data {
    max-width: 106px;
  }
}
//Value for position
.sidebr__value__input {
  margin: 3px;
}
.sidebar__value {
  &__input {
    width: 90px;
    overflow: hidden;
    & .wrapper__input {
      width: 80px;
      & input {
        width: inherit;
      }
    }
  }
  display: flex;
  margin: 10px auto;
  width: 270px;
  & p {
    color: $colorForSidebarText;
    margin: 0;
    font-family: $fontRoboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.2px;
  }
}
//tabs
.wrapper-tabs {
  & p {
    font-size: 14px;
    padding: 0;
    margin: 7px;
  }
}
.tabs_custom {
  border-top: 1px solid #787575;
  border-left: 1px solid #787575;
  border-right: 1px solid #787575;
  box-sizing: border-box;
  border-radius: 2px 2px 0px 0px;
  padding: 4px 2px;
}
.tabs {
  border-right: 1px solid #787575;
  border-left: 1px solid #787575;
  border-top: 1px solid #787575;
  border-radius: 2px;
  padding: 2px;
  padding: 5px 5px;
  width: 100%;
  &_button-group {
    height: 24px;
    border-radius: 2px;
    border: 1px solid #787575;
    display: flex;
    background: #424242;
    & button,
    .button__tab {
      border: none;
      height: 15px;
      box-shadow: none;
      padding: 0 7px;
      margin: 2px 0;
      border-right: 1px solid #535353;
      border-radius: 0;
    }
    & button,
    .button__tab {
      border-right: 2px solid #535353;
    }
    & .button__active-btn {
      background: $Government;
      border: 1px solid #3d88cc;
      box-sizing: border-box;
      box-shadow: -2px -1px 4px rgba(0, 0, 0, 0.25), 2px 1px 4px rgba(0, 0, 0, 0.25);
      border-radius: 2px;
      padding: 0 7px;
      height: 20px;
      margin: 1px;
      position: relative;
    }
  }
  &-help_text {
    position: relative;
    display: inline-block;
    padding-top: 5px;
    font-family: $fontRobotoCondensed;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.2px;
    color: rgba(232, 232, 232, 0.3);
  }
}
.tabs_button-group button,
.tabs_button-group .button__tab:last-child {
  border: none;
}
.choose-setting {
  justify-content: space-around;
  border-radius: 2px;
  padding: 5px;
  width: 100%;
  border-right: 1px solid #787575;
  &.custom_width {
    width: 136px;
  }
  &.custom_max_width_label {
    .choose-setting__radio-button-label {
      width: 181px !important;
    }
  }
  & p {
    font-family: $fontRoboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.2px;
    padding: 0;
    margin: 5px;
  }
}
.choose-setting__radio-button {
  &-label {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    font-family: $fontRobotoCondensed;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.2px;
  }
}
.wrapper-choose-setting {
  display: flex;
  width: 280px;
  margin: 0 auto;
  border: 1px solid #787575;
  border-radius: 0px 0px 2px 2px;
  &:last-child {
    border-right: none;
  }
}
.wrapper-device-setting {
  width: 290px;
  padding: 5px;
  margin: 0 auto;
  & p {
    margin: 7px;
  }
}
.wrapper-table-for-chart {
  margin: 10px;
  & .wrapper-table {
    text-align: center;
    border: 1px solid $borderForSidebar;
    & .col {
      padding: 0;
      border-right: 1px solid $borderForSidebar;
    }
    & .row {
      justify-content: center;
      border-top: 1px solid $borderForSidebar;
    }
    &-info {
      .row {
        justify-content: start;
        overflow: hidden;
      }
    }
  }
}
//choose check box
.wrapper-choose-check-box {
  padding: 10px;
}
.choose-check-box {
  display: flex;
  font-family: $fontRobotoCondensed;
  font-style: normal;
  font-weight: normal;
  padding: 7px;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.2px;
}
//setting machine
.wrapper-setting-machine {
  margin: 10px;
  border: 1px solid $borderForSidebar;
  & .wrapper__input {
    & label {
      width: 150px;
    }
    & input {
      max-width: 100px;
    }
  }
}
//table check box
.wrapper-check-box {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  margin: 15px;
}
//table info
.sidebar_table-info {
  margin-top: 15px;
  & p {
    margin: 0;
    font-family: $fontRoboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: $textForSidebarWhiteTheme;
    letter-spacing: 0.2px;
    padding: 5px 0 5px 5px;
  }
  &_vertical-header {
    height: 31px;
    align-items: center;
  }
}
.wrapper_table-info {
  & p {
    color: $colorForSidebarText;
  }
  padding: 0px 10px 0px 10px;
  top: -15px;
  position: relative;
  & .col {
    padding: 0;
    border-top: 1px solid $borderForSidebar;
    border-bottom: 1px solid $borderForSidebar;
  }
  & .col:nth-child(odd) {
    border-right: 1px solid $borderForSidebar;
    border-left: 1px solid $borderForSidebar;
  }
  & .row {
    justify-content: start;
    overflow: hidden;
    border-top: 1px solid $borderForSidebar;
    & .wrapper__input {
      margin: 0;
      & input {
        border: none;
        padding: 3px 3px 3px 7px;
        margin: 0;
        height: 30px;
      }
    }
  }
}
.vls-sidebar {
  &-title_device_setings {
    margin-top: 2px !important;
  }
  &-title_device_meta_data {
    font-family: $fontRoboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.2px;
    color: $colorForSidebarText;
  }
  &-device_title {
    font-family: $fontRoboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.2px;
    color: $textColorTitleSidebar;
    padding-left: 12px;
    padding-right: 12px;
    position: relative;
    display: block;
    padding-top: 5px;
    padding-bottom: 5px;
    background: #424242;
  }
}
.content-border-line {
  width: 300px;
  height: 2px;
  background: #424242;
  margin: 20px 0;
}
.vls-sidebar-device_title {
  border-bottom: 1px solid #353434;
}

.nav.nav-tabs {
  background: #424242;
  border-bottom: 1px solid #353434;
}

.nav.nav-tabs .vls-sidebar_tabs_wraper {
  &-tab.nav-link {
    font-family: Roboto;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.2px;
    color: rgba(255, 255, 255, 0.5);
    background:  #424242;
    border-radius: 0;
    border: none;
    border-right: 1px solid #353434;
    border-bottom: 1px solid #353434;
    
    &:hover {
      color: rgba(255, 255, 255, 0.5);
    }
    &.active {
      color: #E8E8E8;
      background: #535353;
      border-bottom: none;
    }
    &-input {
      width: 106px;
    }
    &-input_number_label {
      font-family: $fontRoboto;
      font-weight: 500 !important;
      font-size: 14px !important;
    }
    &-input_number {
      border: 1px solid rgba(211, 213, 220, 0.3);
      box-sizing: border-box;
      width: 40px;
      height: 20px;
      position: relative;
      // left: -121px;
    }
    
  }
}
