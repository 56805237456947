.project_details {
  &-content {
    &-wrapper {
      position: relative;
      width: 100%;
      height: 90%;
      max-height: 90%;
      @include lg  {
        height: 98%;
        max-height: 100%;
      }
      @include sm {
        max-height: 102%;
        height: 102%;
      }
      // overflow: hidden;
    }
    &-heading {
      position: relative;
      margin-top: 40px;
      font-weight: bold;
      @include sm {
        position: absolute;
        left: 10%;
        top: -50px;
      }
      a {
        padding: 0.5rem 1rem 1rem 4rem !important;
        color: #fff;
        &:hover {
          text-decoration: none;
          color: #fff;
        }
        &::before {
          content: "";
          position: absolute;
          left: 5px;
          top: -5px;
          height: 30px;
          width: 40px;
          background-size: 100% 100%;
          background-repeat: no-repeat;
          background-image: url("../../../../assets/images/folderLinkBack.png");
        }
      }
    }
    &-line {
      position: absolute;
      width: 100%;
      height: 2px;
      margin-top: 30px;
      background: rgba($color: #fff, $alpha: 0.1);
    }
    &-table_wraper {
      position: relative;
      width: 100%;
      height: 100%;
      margin-top: 60px;
      display: -webkit-inline-box;
      @include lg {
        display: inline-block;
        overflow-y: auto;
        height: 80%;
        &::-webkit-scrollbar {
          width: 10px !important;
        }
        &::-webkit-scrollbar-track {
          background: #535353 !important;
        }
        &::-webkit-scrollbar-thumb {
          background: #25282b !important;
          border: 1px solid #535353;
          box-sizing: border-box;
        }
        &::-webkit-scrollbar-button:vertical:start:decrement {
          position: relative;
          width: 10px;
          background-size: contain;
          background-position-x: center;
          background-position-y: center;
          background-repeat: no-repeat;
          background-color: rgba($color: #535353, $alpha: 1);
          background-image: url("../../../../assets/images/arrowUp.png");
        }
        &::-webkit-scrollbar-button:vertical:end:increment {
          position: relative;
          width: 10px;
          background-size: contain;
          background-position-x: center;
          background-position-y: center;
          background-repeat: no-repeat;
          background-color: rgba($color: #535353, $alpha: 1);
          background-image: url("../../../../assets/images/arrowDown.png");
        }
        &::-webkit-scrollbar-thumb:hover {
          cursor: pointer;
        }
      }
      .table-responsive {
        height: 100%;
        overflow-x: hidden;
      }
      &-pfml_wrap {
        position: relative;
        width: 50%;
        height: 60%;
        padding: 1rem 1rem;
        @include lg {
          width: 100%;
          height: 500px;
        }
        .pfml_wrap {
          &-label {
            font-size: 14px;
            line-height: 15px;
            /* identical to box height, or 107% */
            text-transform: uppercase;
            display: flex;
            align-items: center;
            letter-spacing: 0.2px;
            text-transform: uppercase;
            color: #ffffff;
          }
          &-content {
            position: relative;
            width: 100%;
            height: 100%;
            border: 1px solid #535353;
            margin-top: 10px;
            &-table_wrap {
              position: relative;
              background: #16191d;
              width: 100%;
              height: 85%;
            }
            &-button_wrap {
              position: relative;
              width: 100%;
              height: 15%;
              cursor: pointer;
              border-top: 1px solid #535353;
              &-text {
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 16px;
                display: inline-block;
                align-items: center;
                letter-spacing: 1.25px;
                position: absolute;
                left: 10%;
                top: 35%;
                color: #fff;
                border: none;
                background-color: transparent;
                &:focus {
                  outline: none;
                }
                &:focus {
                  outline: none;
                }
                &:hover {
                  text-decoration: none;
                }
                &::before {
                  content: "";
                  position: absolute;
                  width: 36px;
                  height: 36px;
                  top: -10px;
                  left: -22%;
                  border-radius: 30px;
                  background: $yellowBtn;
                  display: table;
                  box-shadow: 0px 6px 12px rgba(255, 209, 107, 0.32);
                  background-size: 55% 55%;
                  background-position-x: center;
                  background-position-y: center;
                  background-repeat: no-repeat;
                  background-image: url("../../../../assets/images/plus.png");
                }
                @include vg {
                  top: 20px;
                }
                @include lg {
                  left: 10%;
                  top: 25px;
                }
              }
            }
          }
        }
      }
      &-vls_wrap {
        position: relative;
        width: 50%;
        height: 60%;
        padding: 1rem 1rem;
        @include lg {
          top: 50px;
          width: 100%;
          height: 500px;
        }
        // border: 1px solid #fff;
        .vls_wrap {
          &-label {
            font-size: 14px;
            line-height: 15px;
            /* identical to box height, or 107% */
            text-transform: uppercase;
            display: flex;
            align-items: center;
            letter-spacing: 0.2px;
            text-transform: uppercase;
            color: #ffffff;
          }
          &-content {
            position: relative;
            width: 100%;
            height: 100%;
            border: 1px solid #535353;
            margin-top: 10px;
            &-table_wrap {
              position: relative;
              background: #16191d;
              width: 100%;
              height: 85%;
            }
            &-button_wrap {
              position: relative;
              width: 100%;
              min-height: 70px;
              height: 15%;
              cursor: pointer;
              border-top: 1px solid #535353;
              &-text {
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 16px;
                display: inline-block;
                align-items: center;
                letter-spacing: 1.25px;
                position: absolute;
                left: 10%;
                top: 35%;
                color: #fff;
                border: none;
                background-color: transparent;
                &:focus {
                  outline: none;
                }
                &:focus {
                  outline: none;
                }
                &:hover {
                  text-decoration: none;
                }
                &::before {
                  content: "";
                  position: absolute;
                  width: 36px;
                  height: 36px;
                  top: -10px;
                  left: -22%;
                  border-radius: 30px;
                  background: $yellowBtn;
                  display: table;
                  box-shadow: 0px 6px 12px rgba(255, 209, 107, 0.32);
                  background-size: 55% 55%;
                  background-position-x: center;
                  background-position-y: center;
                  background-repeat: no-repeat;
                  background-image: url("../../../../assets/images/plus.png");
                }
                @include vg {
                  top: 20px;
                }
                @include lg {
                  left: 10%;
                  top: 25px;
                }
              }
            }
          }
        }
      }
    }
  }
}
.scrollbar {
  &-wrap_table {
    &::-webkit-scrollbar {
      width: 5px !important;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1 !important;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #25282b !important;
      border: 1px solid #535353;
      box-sizing: border-box;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #25282b !important;
      border: 1px solid #535353;
      box-sizing: border-box;
    }
  }
}
.file_and_folders {
  &-name {
    position: relative;
    display: inline-flex;
    margin: 0px 0px 0px 0px !important;
    width: 100%;
    height: 30px;
    line-height: 35px;

    &-textarea {
      position: relative;
      height: 100%;
      width: 80%;
      font-size: 13px;
      height: 20px;
      top: 8px;
      line-height: 14px;
      text-align: left;
      margin-left: 40px;
      font-family: $fontRoboto;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      display: flex;
      align-items: center;
      letter-spacing: 0.2px;
      background: transparent;
      color: #ffffff;
      border: none;
      resize: none;
      &::-webkit-scrollbar {
        width: 5px !important;
      }
      /* Track */
      &::-webkit-scrollbar-track {
        background: transparent !important;
      }
      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: transparent !important; //#525252
      }
      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #555 !important;
      }
      &:focus {
        outline: none;
      }
      &.active {
        &:focus {
          outline: 1px solid $w;
        }
      }
    }
    &-text_create_folder {
      position: relative;
      left: 40px;
      height: 30px;
      max-width: 100%;
      word-wrap: break-word !important;
    }
    &.calculations {
      background-repeat: no-repeat;
      background-size: 30px 25px;
      background-position-y: 3px;
      background-image: url("../../../../assets/images/calculations.png");
    }
    &.folder {
      background-repeat: no-repeat;
      background-size: 30px 25px;
      background-position-y: center;
      background-image: url("../../../../assets/images/folder-empty.png");
    }
    &.pole {
      background-repeat: no-repeat;
      background-size: 30px 25px;
      background-position-y: center;
      background-image: url("../../../../assets/images/pole.png");
    }
    &.create_folder {
      background-repeat: no-repeat;
      background-size: 30px 25px;
      background-position-y: center;
      background-image: url("../../../../assets/images/createFolder.png");
    }
  }
  &-context_menu {
    margin-left: 30px;
    @include xl {
      margin-left: 0;
    }
    &:hover {
      // background: rgba(255, 255, 255, 0.1);
      background-image: url("../../../../assets/images/menu.png");
      background-repeat: no-repeat;
      opacity: 1;
      background-size: 60%;
      background-position: center;
    }
    &:focus {
      // background: rgba(255, 255, 255, 0.1);
      background-image: url("../../../../assets/images/menu.png");
      background-repeat: no-repeat;
      background-size: 60%;
      background-position: center;
      opacity: 1;
      outline: none;
      display: block;
      // .folder-wrapper {
      //   background: #16191d;
      //   border-radius: 4px;
      // }
    }
    position: relative;
    height: 25px;
    right: 5%;
    top: 3px;
    background: none;
    border: none;
    box-sizing: border-box;
    // border-radius: 2px;
    opacity: 0.5;
    background-image: url("../../../../assets/images/menu.png");
    background-repeat: no-repeat;
    background-size: 60%;
    background-position: center;
    height: 30px;

    background-position-y: center;
  }
}
