.default_page {
  &-header {
    @include flex(sc);
    padding: 17px 0;
    border-bottom: 2px solid black;
    &_main {
      border: none;
    }
    &-col_header {
      padding: 0px !important;
      @include flex(lc);
    }
    &-logo {
      @include flex(lc);
      margin-right: 50px;
      font-family: Montserrat;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 22px;
      letter-spacing: 0.1px;
      cursor: pointer;
      color: #25282b;
      &:hover {
        color: #25282b;
        text-decoration: none;
      }
      @include lg {
        margin-right: 25px;
      }
      &_img {
        width: 55px;
        margin-right: 10px;
        img {
          width: 100%;
          height: auto;
        }
      }
      &_text {
        color:  #007eff;
        @include xxs {
          font-size: 14px;
          line-height: 16px;
        }
      }
    }
    &-menu {
      @include md {
        display: none;
      }

      &-nav {
        ul {
          display: flex;
          margin: 0;
          padding: 0;
          list-style: none;
          li {
            margin-left: 30px;
            @include lg {
              margin-left: 20px;
            }
            &:first-child {
              margin-left: 0px;
            }
            a {
              font-family: Montserrat;
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              line-height: 24px;
              align-items: center;
              letter-spacing: 0.2px;
              color: #25282b;
              transition: .3s;
              &:hover {
                text-decoration: none;
                color: #007eff;
              }
              &.active {
                color: #007eff;
              }
            }
            @include xs {
              margin-left: 0px !important;
            }
          }
        }
      }
      &.active_mob {
        z-index: 6;
        position: fixed;
        left: 70%;
        top: 10%;
        @include xxs {
          left: 10% !important;
        }
        @include md {
          left: 65%;
        }
        @include mob {
          left: 40%;
        }
        .default_page-header-menu-nav {
          ul {
            display: flex;
            flex-flow: column;
            li {
              list-style: none;
              // &:first-child {
              //   margin-left: 0px;
              // }
              margin-top: 30px;
              margin-left: 50px;
              a {
                font-family: Montserrat;
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
                align-items: center;
                letter-spacing: 0.2px;
                color: #fff;
                &:hover {
                  text-decoration: none;
                }
              }
            }
          }
        }
      }
    }
    &-btn_nav {
      @include flex(lc);
      @include lg {
        display: none;
      }
      &-call_info {
        margin-right: 25px;
        position: relative;
        padding-left: 40px;
        cursor: pointer;
        &::before {
          content: "";
          position: absolute;
          width: 30px;
          height: 30px;
          top: 0;
          left: 0;
          margin-top: 10px;
          background-repeat: no-repeat;
          background-size: 90% 100%;
          background-image: url("../../../../assets/images/phone.svg");
        }
        &:hover {
          text-decoration: none;
          &::before {
            top: 0;
            transform: none;
            -webkit-animation: shake-lr 0.8s ease-in-out infinite both;
            animation: shake-lr 0.8s ease-in-out infinite both;
          }
        }
        &-number {
          font-family: Montserrat;
          font-style: normal;
          font-weight: bold;
          font-size: 18px;
          line-height: 16px;
          align-items: center;
          letter-spacing: 2.2px;
          color: #52575c;
          a {
            color: #52575c;
            &:hover {
              color: #52575c;
              text-decoration: none;
            }
          }
        }
        &-text {
          font-family: Montserrat;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 24px;
          align-items: center;
          letter-spacing: 0.2px;
          color: #25282b;
          opacity: 0.5;
        }
      }
      &-sign_in_btn,
      &-registrations_btn {
        display: block;
        padding: 10px 34px;
        border: none;
        background: none;
        border: 1px solid #e8e8e8;
        box-sizing: border-box;
        border-radius: 20px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        align-items: center;
        text-align: center;
        letter-spacing: 0.5px;
        color: #25282b;
        transition: .3s;
        &:hover {
          color: #25282b;
          text-decoration: none;
          background: #e8e8e8;
        }
        &:focus,
        &:active {
          outline: none;
        }
      }
      &-sign_in_btn {
        margin-right: 10px;
      }
      
    }

    &-mob {
      &-burger_menu {
        display: none;
        width: 30px;
        height: 30px;
        z-index: 6;
        cursor: pointer;
        @include nb {
          display: block;
        }

        span {
          position: relative;
          width: 30px;
          height: 2px;
          margin-bottom: 10px;
          background: #000;
          display: block;
          transition: 0.5s;
          &:last-child {
            margin-bottom: 0;
          }
        }
        &.active {
          position: fixed;
          z-index: 6;
          top: 30px;
          right: 25px;
          span {
            background: #fff;
            &:first-child {
              transform: rotateZ(45deg) translateX(9px);
            }
            &:nth-child(2) {
              display: none;
            }
            &:last-child {
              transform: rotateZ(-42deg) translateX(8px);
            }
          }
        }
        @include lg {
          display: inline-block;
        }
      }
      &-block_modal_for_menu {
        @include flex(st);
        @include flexcol(n);
        position: fixed;
        display: none;
        right: 0;
        top: 0;
        width: 300px;
        height: 100vh;
        padding: 25px;
        z-index: 5;
        background: #000;
        @include xxxs {
          width: 100%;
        }
        &.active {
          display: flex;
        }
      }
      &-btn_nav {
        &.active_mob {
          .default_page-header-btn_nav {
            &-call_info {
              margin-bottom: 11px;
              transition: 0.6s;
              a {
                color: #fff;
              }
              &-number {
                color: #fff;
              }
              &-text {
                color: #fff;
              }
            }
            &-sign_in_btn,
            &-registrations_btn {
              color: #fff;
              border: 1px solid #fff;
              &:hover {
                color: #000;
                text-decoration: none;
                background: #fff;
              }
            }
            &-sign_in_btn {
              margin: 0;
              margin-bottom: 11px;
            }
          }
        }
      }
      &-menu {
        &.active_mob {
          .default_page-header-menu-nav {
            display: none;
            margin-top: 70px;
            ul {
              @include flex(lt);
              @include flexcol(n);
              li {
                margin: 0;
                margin-bottom: 8px;
              }
              a {
                color: #fff;
              }
            }
            @include md {
              display: block;
            }
          }
        }
      }
    }
  }
  &-footer {
    position: relative;
    width: 100%;
    height: 372px;
    background: #25282b;
    justify-content: center;
    bottom: 0;
    @include mob {
      height: 925px;
    }
    &-col {
      height: 235px;
      width: 211px !important;
      @include mob {
        overflow: hidden;
        width: 100% !important;
      }
    }
    &-container {
      position: absolute;
      height: 82%;
      // width: 80%;
      display: flex;
      @include lg {
      }
      &-row {
        position: relative;
        width: 100%;
        @include md {
          width: 80%;
        }
        @include mob {
          height: 800px;
          width: 100%;
          flex-direction: column;
        }
      }
      &-row_polit_conf {
        position: absolute;
        bottom: -34px;
        height: 35%;
        width: 100%;
        @include mob {
          bottom: -90px;
          height: 10%;
        }
        &-line {
          position: relative;
          width: 100%;
          height: 1px;
          top: 50px;
          background: #fdfdff;
          opacity: 0.15;
        }
        &-all_rights_reserved {
          position: relative;
          top: 40px;
          font-family: Montserrat;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          display: inline-block;
          line-height: 24px;
          align-items: center;
          letter-spacing: -0.2px;
          color: #fdfdff;
          opacity: 0.5;
          height: 30px;
          @include mob {
            display: block;
            width: 90%;
            top: 52px;
            left: 5%;
          }
        }
        &-privacy_policy {
          position: relative;
          left: 15%;
          top: 40px;
          font-family: Montserrat;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          align-items: center;
          text-align: center;
          letter-spacing: -0.2px;
          text-decoration-line: underline;
          color: #fdfdff;
          opacity: 0.5;
          height: 30px;
          @include lg {
            left: 15%;
          }
          @include mob {
            top: 65px;
          }
          @include xxs {
            left: 3%;
          }
          &:hover {
            text-decoration-line: underline;
            color: #fdfdff;
            opacity: 0.5;
          }
        }
      }
    }
    &-logo {
      position: relative;
      height: 40px;
      top: 35px;
      left: -29%;
      margin-left: 105px;
      display: inline-block;
      font-family: Montserrat;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 22px;
      letter-spacing: 0.1px;
      color: #fdfdff;
      @include mob {
        left: -10%;
      }
      &::before {
        content: "";
        width: 45px;
        height: 65px;
        top: 0px;
        left: -55px;
        position: absolute;
        background-repeat: no-repeat;
        background-size: 100%;
        background-image: url("../../../../assets/images/logoGreen.png");
      }
    }
    &-buy_now_btn {
      position: relative;
      width: 206px;
      height: 56px;
      top: 100px;
      left: -8%;
      padding-right: 30px;
      background: #007eff;
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 16px;
      align-items: center;
      letter-spacing: 0.5px;
      color: #fdfdff;
      border: 1px solid #007eff;
      box-sizing: border-box;
      border-radius: 8px;
      @include mob {
        top: 95px;
        left: -40%;
      }
      @include xxs {
        top: 62px;
        left: 8%;
      }
      @include xs {
        top: 64px;
        left: 5%;
      }
      &::before {
        content: "";
        width: 20px;
        height: 20px;
        top: 16px;
        left: 80%;
        position: absolute;
        background-repeat: no-repeat;
        background-size: 100%;
        background-image: url("../../../../assets/images/ArrowLeftInSphere.svg");
      }
    }
    &-nav_product {
      position: relative;
      top: 12%;
      left: 30%;
      @include mob {
        left: -5%;
      }
      ul {
        li {
          &:first-child {
            opacity: 1;
          }
          @include mob {
            margin-bottom: 25px;
          }
          list-style: none;
          margin-bottom: 35px;
          font-family: Montserrat;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 16px;
          letter-spacing: 0.2px;
          color: #fdfdff;
          opacity: 0.5;
        }
      }
    }
    &-nav_service {
      position: relative;
      top: 12%;
      left: 20%;
      @include mob {
        left: -5%;
      }
      ul {
        li {
          &:first-child {
            opacity: 1;
          }
          @include mob {
            margin-bottom: 25px;
          }
          list-style: none;
          margin-bottom: 35px;
          font-family: Montserrat;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 16px;
          letter-spacing: 0.2px;
          color: #fdfdff;
          opacity: 0.5;
        }
      }
    }
    &-nav_contact {
      position: relative;
      top: 12%;
      left: 5%;
      @include mob {
        left: -5%;
      }
      ul {
        li {
          list-style: none;
          margin-bottom: 35px;
          font-family: Montserrat;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 16px;
          letter-spacing: 0.2px;
          color: #fdfdff;
          opacity: 1;
          @include mob {
            margin-bottom: 25px;
          }
        }
      }
      &-btn_query {
        position: relative;
        width: 176px;
        height: 56px;
        top: -15px;
        border: 1px solid #fdfdff;
        box-sizing: border-box;
        background: transparent;
        color: #fdfdff;
        opacity: 1;
        border-radius: 8px;
      }
    }
  }
  &-content {
    // min-height: 100vh;
    display: block !important;
  }
}
