html {
  scroll-behavior: smooth !important;
}

@mixin fontForBtnHeader {
  font-family: $fontMonserat;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.5px;
  color: $blueText;
  @content;
}
@mixin afterSubTextLine {
  &::after {
    content: "";
    position: absolute;
    width: 126px;
    height: 4px;
    background-color: #007eff;
    left: 0;
    bottom: -25px;
    margin-top: 20px;
    @content;

    @include ph {
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

.container-landing {
  max-width: 1360px !important;
  padding: 0 20px !important;
}
.landing {
  &-main {
    overflow: hidden;
  }
  &-header {
    &-wrapper {
      @include flex(lc);
      width: 100%;
      padding: 20px 0;
      background-color: $w;
      &-logo {
        flex-shrink: 0;
        margin-right: auto;
        width: 230px;
        height: 65px;
        cursor: pointer;
        background-image: url("../../../../assets/images/logoForMainPage.svg");
        background-repeat: no-repeat;
        background-size: contain;
        @include mob {
          width: 185px;
          height: 55px;
        }
        @include xxs {
          width: 140px;
          height: 40px;
        }
      }
      &-menu {
        position: relative;
        margin-left: 72px;
        margin-right: auto;
        padding-left: 40px;
        font-family: $fontMonserat;
        font-weight: bold;
        font-size: 18px;
        text-transform: uppercase;
        color: $black;
        cursor: pointer;
        @include xl {
          margin-left: 40px;
        }
        @include mob {
          margin-left: 20px;
        }
        @include lg {
          span {
            display: none;
          }
          &::before {
            content: "";
            top: 22px;
            left: -40px;
            position: absolute;
            width: 30px;
            height: 22px;
            background-size: contain;
            background-repeat: no-repeat;
            background-image: url("../../../../assets/images/menuBurgerForLanding.svg");
          }
        }
        &::before {
          content: "";
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 30px;
          height: 22px;
          background-image: url("../../../../assets/images/menuBurgerForLanding.svg");
          background-repeat: no-repeat;
        }
      }
      &-phone {
        position: relative;
        display: block;
        padding-left: 40px;
        font-family: $fontMonserat;
        font-weight: bold;
        font-size: 20px;
        color: $black;
        transition: .3s;
        &::before {
          content: "";
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 30px;
          height: 30px;
          background-image: url("../../../../assets/images/phone.svg");
          background-repeat: no-repeat;
          transition: background-image .3s ease-in;
        }
        &:hover {
          text-decoration: none;
          color: $blueText;
          &::before {
            background-image: url("../../../../assets/images/phone-blue.svg");
            // top: 0;
            // transform: none;
            // -webkit-animation: shake-lr 0.8s ease-in-out infinite both;
            // animation: shake-lr 0.8s ease-in-out infinite both;
          }
        }
        @include md {
          font-size: 0;
        }
        @include sm {
          font-size: 20px;
        }
      }
      &-btn {
        display: block;
        min-width: 150px;
        padding: 20px 30px;
        border: 2px solid $blueText;
        border-radius: 30px;
        transition: .3s;
        &:hover {
          text-decoration: none;
        }
        &-sign_in {
          margin-left: 60px;
          background-color: $w;
          @include fontForBtnHeader;         

          &:hover {
            background-color: $blueText;
            color: $w;
          }
          @include md {
            margin-left: 20px;
          }
        }
        &-registrations {
          margin-left: 20px;
          background-color: $blueText;
          @include fontForBtnHeader {
            color: $w;
          }
          
          &:hover {
            background-color: lighten($color: $blueText, $amount: 3%);
            color: $w;
          }
        }
      }
    }
  }
  &-first_screen_section {
    background-image: url("../../../../assets/images/backgroundHeadSectionLanding.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    &-wrapper {
      padding-top: 125px;   
      @include sm {
        padding-top: 105px;
      }
      @include xs {
        padding-top: 75px;
      }
      &-title {
        margin-bottom: 15px;
        font-family: $fontMonserat;
        font-weight: bold;
        font-size: 54px;
        line-height: 54px;
        text-align: center;
        color: $lightThemeBlocks;
        @include sm {
          font-size: 42px;
        }
        @include xs {
          font-size: 36px;
        }
      }
      &-subtitle {
        display: block;
        max-width: 850px;
        margin: 0 auto;
        margin-bottom: 60px;
        font-family: $fontMonserat;
        font-weight: 500;
        font-size: 18px;
        line-height: 140%;
        text-align: center;
        color: rgba(255, 255, 255, 0.8);
        @include md {
          max-width: 650px;
        }
        @include xs {
          max-width: 480px;
          font-size: 16px;
        }
        @include xxs {
          // max-width: 180px;
        }
      }
      &-screen {
        max-width: 1078px;
        margin: 0 auto;
        border-radius: 20px 20px 0px 0px;
        max-height: 590px;
        background-color: $w;
        border: 4px solid #e0e0e0;
        border-bottom: none;
        box-shadow: 0px 0px 84px rgba(0, 0, 0, 0.15);
        overflow: hidden;
        @include xs {
          border-radius: 12px 12px 0px 0px;
        }
        video {
          width: 100%;
          padding: 7px;
          padding-bottom: 0;
          border-radius: 17px 17px 0 0;
          @include xs {
            border-radius: 8px 8px 0px 0px;
            padding: 5px;
          }
        }
        &-img {
          width: 100%;
          padding: 7px;
          padding-bottom: 0;
          border-radius: 17px 17px 0 0;
          object-fit: cover;
          @include xs {
            border-radius: 8px 8px 0px 0px;
            padding: 5px;
          }
        }
      }
    }
  }
  &-about_service_section {
    background: linear-gradient(180deg, #ffffff 0%, #f7f8ff 100%);
    &-wrapper {
      padding: 100px 0 76px 0;
      @include sm {
        padding-top: 60px;
      }
      &-service_info {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-areas: 
        "title image"
        "subtitle image"
        "button image";
        align-items: start;
        margin-bottom: 124px;
        @include md {
          grid-template-columns: 100%;
          grid-template-areas: 
          "title"
          "image"
          "subtitle"
          "button";
        }
        @include sm {
          margin-bottom: 70px;
        }
        &-title {
          grid-area: title;
          position: relative;
          margin-bottom: 20px;
          font-family: $fontMonserat;
          font-style: normal;
          font-weight: bold;
          font-size: 40px;
          line-height: 49px;
          letter-spacing: 0.1px;
          color: $darckThemeBody;
          @include afterSubTextLine;

          @include xs {
            font-size: 30px;
          }

          @include ph {
            text-align: center;
          }
        }
        &-subtitle {
          grid-area: subtitle;
          padding-top: 40px;
          font-family: $fontMonserat;
          font-weight: 500;
          font-size: 18px;
          line-height: 160%;
          color: $subTextColor;
          opacity: 0.7;

          &_text {
            padding-right: 50px;

            @include sm {
              padding-right: 0;
            }
            
          }

          @include xs {
            font-size: 16px;
          }

          @include ph {
            text-align: center;
          }
        }
        &-link {
          grid-area: button;
          position: relative;
          display: block;
          max-width: 270px;
          margin-top: 50px;
          padding: 16px 30px;
          // padding-right: 60px;
          background: $blueLinkText;
          border: none;
          border-radius: 28px;
          font-family: $fontMonserat;
          color: $w;
          font-weight: bold;
          font-size: 16px;
          letter-spacing: 0.5px;
          transition: .3s;
          text-align: center;
          outline: none;
          &:focus,
          &:active {
            outline: none;
            border: none;
          }
          // &::after {
          //   content: "";
          //   position: absolute;
          //   top: 50%;
          //   transform: translateY(-50%);
          //   width: 20px;
          //   height: 20px;
          //   margin-left: 10px;
          //   background-image: url("../../../../assets/images/ArrowLeftInSphere.svg");
          //   background-size: 100%;
          //   background-position: center;
          //   background-repeat: no-repeat;
          // }
          &:hover {
            background-color: lighten($color: $blueLinkText, $amount: 3%);
            color: $w;
            text-decoration: none;
          }

          @include ph {
            margin: 0 auto;
            margin-top: 50px;
          }
        }
        &-image {
          grid-area: image;
          padding: 7px;
          background-color: $w;
          border-radius: 20px;
          box-shadow: 0px 0px 54px #F2F2FA;
          overflow: hidden;

          @include md {
            max-width: 750px;
            margin-top: 40px;
          }

          &-img {
            width: 100%;
            border-radius: 17px;
            @include xxs {
              padding: 0px;
            }
          }
        }
      }
      &-service_allows {
        &-title {
          position: relative;
          margin-bottom: 20px;
          font-family: $fontMonserat;
          font-weight: bold;
          font-size: 40px;
          line-height: 49px;
          letter-spacing: 0.1px;
          color: $darckThemeBody;
          z-index: 1;
          @include afterSubTextLine;
          @include xs {
            font-size: 30px;
          }
          @include ph {
            text-align: center;
          }
        }
        &-slider {
          position: relative;
          width: 100%;
          padding-top: 36px;
          z-index: 1;
          .slick-slide {
            padding-right: 15px;
            @include ph {
              padding: 0 8px;
            }
          }
          .slick-list {
            height: 330px;
            overflow: visible;
          }
          .slick-track {
            top: 30px;
            left: 0;
          }
          &-wrapper {
            position: relative;
            min-height: 250px;
            padding: 26px;
            padding-top: 60px;
            z-index: -1;
            background-color: $w;
            border-radius: 30px;
            outline: none;
            box-shadow: 0px 0px 80px rgba(75, 85, 116, 0.1);
            @include sm {
              height: 300px;
            }
            @include mob {
              height: 250px;
            }
          }
          &-img {
            position: absolute;
            width: 80px;
            height: 80px;
            object-fit: contain;
            left: 25px;
            top: -30px;
          }
          &-number {
            position: absolute;
            top: 26px;
            right: 26px;
            font-family: $fontMonserat;
            font-weight: bold;
            font-size: 34px;
            line-height: 24px;
            text-align: right;
            text-transform: capitalize;
            text-shadow: -0 -1px 0 #d8d8df, 0 -1px 0 #d8d8df, -0 1px 0 #d8d8df,
              0 1px 0 #d8d8df, -1px -0 0 #d8d8df, 1px -0 0 #d8d8df,
              -1px 0 0 #d8d8df, 1px 0 0 #d8d8df, -1px -1px 0 #d8d8df,
              1px -1px 0 #d8d8df, -1px 1px 0 #d8d8df, 1px 1px 0 #d8d8df,
              -1px -1px 0 #d8d8df, 1px -1px 0 #d8d8df, -1px 1px 0 #d8d8df,
              1px 1px 0 #d8d8df;
            color: $w;
          }
          &-title {
            font-family: $fontMonserat;
            font-weight: bold;
            font-size: 24px;
            line-height: 24px;
            text-transform: capitalize;
            color: $blueLinkText;
          }
          &-content {
            margin-top: 15px;
            font-family: $fontMonserat;
            font-weight: 500;
            font-size: 16px;
            line-height: 160%;
            letter-spacing: 0.1px;
            color: $black;
            opacity: 0.7;
            &::-webkit-scrollbar {
              width: 5px !important;
            }
            /* Track */
            &::-webkit-scrollbar-track {
              background: $w !important;
              &:hover {
                background: rgb(223, 223, 223) !important;
                &::-webkit-scrollbar-thumb {
                  background: rgb(223, 223, 223) !important;
                }
              }
            }
            /* Handle */
            &::-webkit-scrollbar-thumb {
              background: $w !important;
              &:hover {
                background: rgb(129, 129, 129) !important; //#525252
              }
            }
          }
          &-arrow {
            position: absolute;
            top: -66px;
            right: 0px;
            border: 1px solid #4279f1;
            box-sizing: border-box;
            border-radius: 28px;
            width: 50px;
            height: 50px;
            background-repeat: no-repeat;
            background-size: 100%;
            background-position: center;
            transition: .3s;
            cursor: pointer;
            z-index: 10;
            &:hover {
              background-color: #4279f1;
            }
            @include mob {
              // top: -40px;
            }
            &.left { 
              right: 63px;
              background-image: url("../../../../assets/images/ArrowForPaginationLeft.svg");
              &:hover {
                background-image: url("../../../../assets/images/ArrowForPaginationLeftWhite.svg");
              }
              @include ph {
                right: 80px;
              }
              @include xxs {
                right: 80px;
              }
            }
            &.right {
              background-image: url("../../../../assets/images/ArrowForPaginationRight.svg");
              &:hover {
                background-image: url("../../../../assets/images/ArrowForPaginationRightWhite.svg");
              }
              @include ph {
                right: 15px;
              }
              @include xxs {
                right: 20px;
              }
            }
          }
        }
      }
    }
  }
  &-whom_is_it_suitable {
    &-wrapper {
      padding: 100px 0;

      @include md {
        padding: 60px 0;
      }
      &-title {
        position: relative;
        font-family: $fontMonserat;
        font-weight: bold;
        text-align: center;
        font-size: 40px;
        line-height: 49px;
        letter-spacing: 0.1px;
        color: $darckThemeBody;
        @include afterSubTextLine;
        &::after {
          left: 50%;
          transform: translateX(-50%);
        }
        @include xs {
          font-size: 30px;
        }
      }
      &-wrapper_exemple_use {
        display: flex;
        justify-content: center;
        width: 100%;
        margin-top: 80px;
        @include md {
          flex-direction: column;
        }
      }
      &-item {
        @include flex(lt);
        @include flexcol(n);
        width: 100%;
        max-width: 630px;
        background: #fdfdff;
        border: 1px solid #f2f2f2;
        box-sizing: border-box;
        box-shadow: 0px 0px 80px rgba(75, 85, 116, 0.1);
        border-radius: 40px;
        overflow: hidden;
        @include md {
          max-width: 100%;
        }
        &:first-child {
          margin-right: 60px;
          @include lg {
            margin-right: 40px;
          }
          @include md {
            margin: 0;
            margin-bottom: 30px;
          }
        }

        &-img {
          width: 100%;
          height: auto;
          object-fit: cover;
        }
        &-content {
          padding: 40px 45px 45px 50px;
          @include flex(st);
          @include flexcol(n);
          flex-grow: 1;
          @include lg {
            padding: 30px 30px 40px 30px;
          }
          @include xs {
            padding: 20px 20px 30px 20px;
          }
        }
        &-title {
          font-family: $fontMonserat;
          font-weight: bold;
          font-size: 24px;
          line-height: 140%;
          color: $subTextColor;
          margin-bottom: 35px;
          @include md {
            font-size: 22px;
          }
          @include ph {
            font-size: 18px;
            margin-bottom: 18px;
          }
        }
        &-variant {
          position: relative;
          @include flex(lt);
          margin-bottom: 20px;
          &:last-child {
            margin-bottom: 30px;
            @include xxs {
              margin-bottom: 15px;
            }
          }
          span {
            font-family: $fontMonserat;
            font-weight: 500;
            font-size: 16px;
            line-height: 160%;
            color: $black;
            opacity: 0.7;
            @include xs {
              font-size: 14px;
            }
          }
          &::before {
            content: "";
            display: block;
            width: 40px;
            height: 40px;
            margin-right: 18px;
            flex-shrink: 0;
            background-size: 100%;
            background-position: center;
            background-repeat: no-repeat;
            background-image: url("../../../../assets/images/checkVariant.svg");
            @include xs {
              width: 30px;
              height: 30px;
              margin-right: 12px;
            }
          }
        }
        &-link {
          align-self: flex-end;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 11px 30px;
          color: $blueText;
          background-color: $w;
          border: 2px solid $blueText;
          box-sizing: border-box;
          border-radius: 30px;
          font-family: $fontMonserat;
          font-weight: bold;
          font-size: 16px;
          letter-spacing: 0.5px;
          transition: .3s;
          @include xs {
            width: 100%;
          }
          &::after {
            content: "";
            display: block;
            width: 13px;
            height: 13px;
            margin-left: 10px;
            background-size: 100%;
            background-position: center;
            background-repeat: no-repeat;
            background-image: url("../../../../assets/images/arrowForLink.svg");
            transition: .3s;
          }
          &:hover {
            text-decoration: none;
            background: $blueText;
            color: $w;
            &::after {
              background-image: url("../../../../assets/images/arrowForLinkWhite.svg");
            }
          }
        }
      }
    }
  }
  &-how_does_it_work {
    background: linear-gradient(0deg, #ffffff 0%, #f5f6ff 100%);
    &-wrapper {
      padding: 100px 0;
      @include md {
        padding: 60px 0;
      }
      @include xs {
        padding: 30px 0;
      }
      &-title {
        margin-bottom: 25px;
        position: relative;
        font-family: $fontMonserat;
        font-weight: bold;
        text-align: center;
        font-size: 40px;
        line-height: 49px;
        letter-spacing: 0.1px;
        color: $darckThemeBody;
        @include afterSubTextLine {
          left: 50%;
          transform: translateX(-50%);
        }
        @include xs {
          font-size: 30px;
        }
      }
      &-slider {
        position: relative;
        width: 100%;
        margin: 0 auto;
        // padding: 0 85px;
        // padding-top: 62px;
        overflow: hidden;
        // @include sm {
        //   padding: 30px 30px 0;
        // }
        // @include xs {
        //   padding: 30px 15px;
        //   padding-bottom: 0;
        // }
        .slider-media {
          .slick-list {
            padding: 62px 0;
            @include sm {
              padding: 30px 0;
            }
          }
          @include sm {
            margin-bottom: 30px;
          }
        }
        .slick-list {
          // overflow: visible;
        }
        .slick-slide {
          // visibility: hidden;
          &.slick-active {
            &.slick-current {
              // visibility: visible;
            }
          }
        }
        &-content {
          width: 85%;
          margin: 0 auto;
          padding: 40px 20px;
          padding-bottom: 0;
          @include sm {
            width: 100%;
            text-align: center;
          }
        }
        &-title {
          font-family: $fontMonserat;
          font-weight: bold;
          font-size: 24px;
          line-height: 140%;
          color: $subTextColor;
        }
        &-text_content {
          font-family: $fontMonserat;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 160%;
          color: #2a323a;
          opacity: 0.7;
        }
        &-wrapper {
          width: 85%;
          max-width: 1140px;
          margin: 0 auto;
          position: relative;
          outline: none;
          @include sm {
            width: 100%;
          }
          &-bg {
            position: relative;
            height: auto;
            background: #fdfdff;
            border: 1px solid #f2f2f2;
            box-sizing: border-box;
            box-shadow: 0px 0px 80px rgba(75, 85, 116, 0.1);
            border-radius: 40px;
            padding: 10px;
            @include xs {
              border-radius: 20px;
            }
            @include ph {
              padding: 5px;
            }
            @include sm {
              box-shadow: 0px 0px 30px rgba(75, 85, 116, 0.1);
            }

          }
          &-video_wrapper {
            position: relative;
            width: 100%;
            display: block;
            height: auto;
            border-radius: 30px;
            overflow: hidden;
            @include xs {
              border-radius: 20px;
            }
            .react-player {
              padding-top: 56.25%;
              position: relative;
              & > div {
                position: absolute;
                top: 0;
                left: 0;
              }
            }
          }
          &-img_wrapper {
            position: relative;
            width: 100%;
            display: block;
            height: auto;
            border-radius: 40px;
            @include xs {
              border-radius: 20px;
            }
          }
          &-img {
            position: relative;
            display: block;
            width: 100%;
            height: auto;
            object-fit: cover;
            border-radius: 30px;
            @include xs {
              border-radius: 20px;
            }
          }
          &-arrow {
            position: absolute;
            width: 50px;
            height: 50px;
            right: 0px;
            top: 50%;
            transform: translateY(-50%);
            background-repeat: no-repeat;
            background-size: 100%;
            background-position: center;
            border: 1px solid #4279f1;
            border-radius: 28px;
            z-index: 90;
            cursor: pointer;
            transition: .3s;
            &:hover {
              background-color: #4279f1;
            }
            &.left {
              left: calc(50% - 1140px/2 - 80px);
              background-image: url("../../../../assets/images/ArrowForPaginationLeft.svg");
              &:hover {
                background-image: url("../../../../assets/images/ArrowForPaginationLeftWhite.svg");
              }
              // @include xxxl {
              //   left: 300px;
              // }
              @include vg {
                left: 30px;
              }
              @include md {
                left: 15px;
              }
            }
            &.right {
              right: calc(50% - 1140px/2 - 80px);
              background-image: url("../../../../assets/images/ArrowForPaginationRight.svg");
              &:hover {
                background-image: url("../../../../assets/images/ArrowForPaginationRightWhite.svg");
              }
              // @include xxxl {
              //   right: 300px;
              // }
              @include vg {
                right: 30px;
              }
              @include md {
                right: 15px;
              }
            }
          }
        }
      }
    }
  }
  &-features_section {
    background: #fdfdff;
    &-wrapper {
      padding: 100px 0;
      @include md {
        padding: 60px 0;
      }
      @include xs {
        padding: 30px 0;
      }
      &-title {
        margin-bottom: 25px;
        position: relative;
        font-family: $fontMonserat;
        font-weight: bold;
        text-align: center;
        font-size: 40px;
        line-height: 49px;
        letter-spacing: 0.1px;
        color: $darckThemeBody;
        @include afterSubTextLine {
          left: 50%;
          transform: translateX(-50%);
        }
        @include xs {
          font-size: 30px;
        }
      }
      &-subtitle {
        max-width: 1096px;
        margin: 0 auto;
        margin-top: 70px;
        margin-bottom: 60px;
        font-family: $fontMonserat;
        font-size: 18px;
        line-height: 160%;
        text-align: center;
        color: $subTextColor;
        opacity: 0.7;
        span {
          font-weight: bold;
        }
        @include xs {
          font-size: 16px;
        }
      }
      &-grid_features {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 30px;
        @include lg {
          grid-template-columns: repeat(2, 1fr);
          grid-gap: 20px;
        }
        @include sm {
          grid-template-columns: 1fr;
          grid-gap: 15px;
        }
        &-item {
          padding: 30px;
          padding-bottom: 38px;
          background: #fff;
          border: 1px solid #f2f3f7;
          border-radius: 20px;
          @include sm {
            padding: 25px 20px;
          }
          &-header {
            @include flex(sc);
            margin-bottom: 17px;
          }
          &-icon {
            width: 70px;
            height: 70px;
            border-radius: 50%;
            background-size: 70%;
            background-position: center;
            background-repeat: no-repeat;
            @include ph {
              width: 50px;
              height: 50px;
              // background-size: 0%;
            }
            &.use {
              background-color: #ff6262;
              box-shadow: 0px 4px 20px rgba(255, 98, 98, 0.35);
              background-image: url("../../../../assets/images/useIcon.svg");
            }
            &.cont {
              background-color: #6dc9c8;
              box-shadow: 0px 4px 20px rgba(109, 201, 200, 0.35);
              background-image: url("../../../../assets/images/accountingIcon.svg");
            }
            &.server {
              background-color: #ffbc1f;
              box-shadow: 0px 4px 20px rgba(255, 188, 31, 0.35);
              background-image: url("../../../../assets/images/serverIcon.svg");
            }
            &.useTwoType {
              background-color: #4a9eea;
              box-shadow: 0px 4px 20px rgba(74, 158, 234, 0.35);
              background-image: url("../../../../assets/images/usePIcon.svg");
            }
            &.env {
              background-color: #b887f8;
              box-shadow: 0px 4px 20px rgba(184, 135, 248, 0.35);
              background-image: url("../../../../assets/images/envIcon.svg");
            }
            &.twoLib {
              background-color: #40dd7f;
              box-shadow: 0px 4px 20px rgba(64, 221, 127, 0.35);
              background-image: url("../../../../assets/images/twoLibsIcon.svg");
            }
          }
          &-title {
            position: relative;
            font-family: $fontMonserat;
            font-weight: bold;
            font-size: 20px;
            line-height: 140%;
            letter-spacing: 0.2px;
            color: $black;
            &.use {
              @include afterSubTextLine {
                background-color: #ff6262;
              }
            }
            &.cont {
              @include afterSubTextLine {
                background-color: #6dc9c8;
              }
            }
            &.server {
              @include afterSubTextLine {
                background-color: #ffbc1f;
              }
            }
            &.useTwoType {
              @include afterSubTextLine {
                background-color: #4a9eea;
              }
            }
            &.env {
              @include afterSubTextLine {
                background-color: #b887f8;
              }
            }
            &.twoLib {
              @include afterSubTextLine {
                background-color: #40dd7f;
              }
            }
            &::after {
              @include ph {
                left: 0 !important;
                transform: none !important;
              }
            }
          }
          &-content {
            position: relative;
            font-family: $fontMonserat;
            font-weight: 500;
            font-size: 16px;
            line-height: 160%;
            color: $subTextColor;
            opacity: 0.7;
            ul {
              padding: 0;
              margin: 0;
              li {
                padding: 0;
                margin: 0;
                margin-bottom: 15px;
                &:last-child {
                  margin: 0;
                }
              }
            }
          }
        }
      }
    }
  }
  &-footer {
    background: #25282b;
    &-wrapper {
      &-top {
        padding: 30px 0 40px 0;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-areas: 
          "info menu contact";
        align-items: center;
        grid-gap: 30px;
        border-bottom: 1px solid rgba(253,253,255, 0.15);
        @include md {
          grid-template-columns: 100%;
          grid-gap: 45px 30px;
          grid-template-areas: 
          "info"
          "menu"
          "contact";
        }
        @include sm {
          grid-gap: 30px;
        }
      }
      &-info {
        grid-area: info;
        @include md {
          @include flex(lc);
          justify-content: space-between;
        }
        @include xs {
          justify-content: flex-start;
          align-items: flex-start;
          @include flexcol(n);
        }
        @include ph {
          align-items: center;
        }
        &-logo {
          @include flex(lc);
          // margin-right: 50px;
          font-family: Montserrat;
          font-style: normal;
          font-weight: bold;
          font-size: 18px;
          line-height: 22px;
          letter-spacing: 0.1px;
          cursor: pointer;
          color: #25282b;
          &:hover {
            color: #25282b;
            text-decoration: none;
          }
          @include lg {
            margin-right: 25px;
          }
          &_img {
            width: 55px;
            margin-right: 10px;
            img {
              width: 100%;
              height: auto;
            }
          }
          &_text {
            color:  #fff;
            // width: 80px;
          }
        }
        &-link {
          display: block;
          margin-top: 40px;
          position: relative;
          max-width: 273px;
          padding: 16px 30px;
          padding-right: 60px;
          background: $blueLinkText;
          border-radius: 28px;
          font-family: $fontMonserat;
          color: $w;
          font-weight: bold;
          font-size: 16px;
          letter-spacing: 0.5px;
          transition: .3s;
          text-align: center;
          &::after {
            content: "";
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 20px;
            height: 20px;
            margin-left: 10px;
            background-image: url("../../../../assets/images/ArrowLeftInSphere.svg");
            background-size: 100%;
            background-position: center;
            background-repeat: no-repeat;
          }
          &:hover {
            background-color: lighten($color: $blueLinkText, $amount: 3%);
            color: $w;
            text-decoration: none;
          }
          @include md {
            margin: 0;
          }
          @include xs {
            margin-top: 40px;
          }
        }
      }
      &-menu {
        grid-area: menu;
        nav {
          width: 100%;
          height: 100%;
          ul {
            margin: 0;
            padding: 0;
            width: 100%;
            height: 100%;
            display: grid;
            grid-template-columns: auto;
            grid-template-rows: repeat(2, auto); //3
            grid-auto-flow: column;
            grid-gap: 37px;
            @include md {
              grid-template-rows: auto;
              justify-content: space-between;
            }
            @include sm {
              grid-template-rows: repeat(2, auto);
              grid-gap: 20px;
            }
            @include xs {
              justify-content: start;
              text-align: left;
              grid-template-rows: repeat(2, auto);//3
              grid-template-columns: repeat(2, 1fr);
            }
            @include ph {
              justify-content: center;
              grid-template-columns: 1fr;
              text-align: center;
              grid-template-rows: auto;
              grid-auto-flow: row;
            }
            li {
              font-family: $fontMonserat;
              font-weight: 600;
              font-size: 16px;
              line-height: 16px;
              letter-spacing: 0.2px;
              opacity: 0.5;
              list-style: none;
              cursor: pointer;
              a {
                color: rgba(253,253,255, 0.55);
                transition: .3s;
                &:hover {
                  color: $w;
                  text-decoration: none;
                }
                &.active {
                  color: $w;
                }
              }
            }
          }
        }
      }
      &-contact {
        grid-area: contact;
        @include flex(lb);
        @include flexcol(n);
        @include md {
          flex-flow: row nowrap;
          justify-content: space-between;
          align-items: center;
        }
        @include xs {
          flex-flow: column-reverse nowrap;
          align-items: flex-start;
        }
        @include ph {
          align-items: center;
        }
        &-items {
          @include flex(lb);
          @include flexcol(n);
          @include md {
            align-items: flex-start;
          }
          @include ph {
            align-items: center;
          }
        }
        &-phone,
        &-email {
          display: block;
          font-family: $fontMonserat;
          font-weight: bold;
          font-size: 18px;
          line-height: 16px;
          text-align: right;
          letter-spacing: 0.2px;
          color: rgba(253,253,255, 0.5);
          margin-bottom: 30px;
          transition: .3s;
          &:hover {
            text-decoration: none;
            color: rgba(253,253,255, 0.75);
          }
        }
        &-phone {      
          @include ph {
            margin-bottom: 20px;
          }
        }
        &-email {
          @include md {
            margin: 0;
          }
        }
        &-button {
          width: auto;
          margin: 0;
          padding: 16px 40px;
          border-radius: 30px;
          font-family: $fontMonserat;
          font-weight: bold;
          font-size: 16px;
          line-height: 16px;
          text-align: center;
          letter-spacing: 0.5px;
          background-color: transparent !important;
          color: #ebecf0;
          border: 2px solid #ebecf0;
          transition: .3s;
          &:hover {
            background-color: lighten( #25282b, 3%) !important;
          }
          @include xs {
            margin-bottom: 30px;
          }
        }
      }
      &-bottom {
        padding: 30px 0;
        @include flex(sc);
        flex-wrap: wrap;
        @include md {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-gap: 30px;
        }
        @include sm {
          grid-template-columns: 1fr;
          grid-gap: 15px;
        }
        @include ph {
          justify-content: center;
        }
        &-license {
          font-family: $fontMonserat;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: -0.2px;
          color: rgba(253,253,255, 0.5);
          @include ph {
            text-align: center;
          }
        }
        &-right {
          @include flex(lc);
          @include md {
            justify-content: end;
          }
          @include sm {
            justify-content: space-between;
          }
          @include ph {
            flex-direction: column;
            align-items: center;
          }
        }
        &-politics {
          // margin-right: 25px;
          font-family: Montserrat;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: -0.2px;
          text-decoration: underline;
          color: rgba(253,253,255, 0.5);
          transition: .3s;
          &:hover {
            color: rgba(253,253,255, 0.75);
          }
          @include sm {
            margin: 0;
          }
          @include ph {
            // margin-bottom: 20px;
          }
        }
        &-btn {
          display: none;
          position: fixed;
          bottom: 65px;
          right: 65px;
          margin: 0;
          padding: 0;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          background-size: 60%;
          background-position: center;
          background-repeat: no-repeat;
          background-color: #fff;
          background-image: url("../../../../assets/images/arrow-top-landing.svg");
          box-shadow: 0 0 40px 0px rgba(99, 99, 99, 0.15);
          z-index: 4;
          @include xl {
            right: 20px;
          }
          &.active {
            display: block;
          }
        }
      }
    }
  }
}
.min_menu {
  &-wrapper {
    position: absolute;
    width: 100%;
    @include flex(sc);
    padding: 20px;
    background: $w;
    z-index: 99999999999999;
    box-shadow: 0 40px 80px rgba(75, 85, 116, 0.1);
    @include mob {
      @include flexcol(n);
    }
    .landing-header-wrapper {
      &-phone {
        display: block;
        @include mob {
          margin-bottom: 20px;
        }
        @include xxs {
          margin-bottom: 10px;
        }
      }
      &-btns {
        @include flex(lc);
        @include mob {
          @include flexcol(n);
        }
      }
      &-btn {
        &-sign_in {
          margin: 0;
          @include mob {
            margin-bottom: 20px;
          }
          @include xxs {
            margin-bottom: 10px;
          }
        }
        &-registrations {
          margin: 0;
          margin-left: 10px;
          @include mob {
            margin: 0;
          }
        }
      }
    }
    
    &-close_btn {
      position: absolute;
      width: 30px;
      height: 30px;
      right: 18px;
      top: 18px;
      z-index: 99999999;
      background-image: url("../../../../assets/images/closeModalWhite.svg");
      background-repeat: no-repeat;
      background-size: contain;
      cursor: pointer;
    }
  }
  &-closed {
    position: fixed;
    display: block;
    width: 60%;
    height: 100%;
    background-color: transparent;
    top: 0px;
    left: 0px;
    z-index: 99999999999999;
    @include sm {
      width: 40%;
    }
    @include mob {
      width: 20%;
    }
    @include xxs {
      width: 0%;
    }
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2020-10-9 17:47:34
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation shake-lr
 * ----------------------------------------
 */
 @-webkit-keyframes shake-lr {
  0%,
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
  }
  10% {
    -webkit-transform: rotate(8deg);
            transform: rotate(8deg);
  }
  20%,
  40%,
  60% {
    -webkit-transform: rotate(-10deg);
            transform: rotate(-10deg);
  }
  30%,
  50%,
  70% {
    -webkit-transform: rotate(10deg);
            transform: rotate(10deg);
  }
  80% {
    -webkit-transform: rotate(-8deg);
            transform: rotate(-8deg);
  }
  90% {
    -webkit-transform: rotate(8deg);
            transform: rotate(8deg);
  }
}
@keyframes shake-lr {
  0%,
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
  }
  10% {
    -webkit-transform: rotate(8deg);
            transform: rotate(8deg);
  }
  20%,
  40%,
  60% {
    -webkit-transform: rotate(-10deg);
            transform: rotate(-10deg);
  }
  30%,
  50%,
  70% {
    -webkit-transform: rotate(10deg);
            transform: rotate(10deg);
  }
  80% {
    -webkit-transform: rotate(-8deg);
            transform: rotate(-8deg);
  }
  90% {
    -webkit-transform: rotate(8deg);
            transform: rotate(8deg);
  }
}
