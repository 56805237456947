.table {
  color: #fff !important;
  &-light {
    color: $black !important;
  }
  &-all_project tbody tr:hover {
    color: #fff;
    background: rgba(255, 255, 255, 0.1) !important;
  }
  &-project_details {
    &.table-hover tbody tr:hover {
      border: 1px solid $BlueEmpty;
      border-right: 2px solid $BlueEmpty;
      background: rgba(255, 255, 255, 0.1) !important;
      // textarea {
      //   background: rgba(255, 255, 255, 0) !important;
      // }
    }
    thead tr th,
    .table th {
      color: rgba($color: #fff, $alpha: 0.3);
      font-size: 13px;
      border-bottom: none !important;
    }
    tbody tr td,
    .table th,
    .table td {
      border: none !important;
      border-top: none !important;
      padding: 0px 0px 0px 10px !important;
    }
    .table thead tr th,
    .table th {
      border-bottom: none !important;
    }

    // tbody tr:hover {
    //   background: rgba(255, 255, 255, 0.1) !important;
    //   border: 1px solid #4a9eea;
    //   border-right: 2px solid #4a9eea;
    //   .table th,
    //   .table td {
    //     border: 1px solid #4a9eea;
    //   }
    // }
  }
  &-vls-table_data-oscilloscope-table {
    border-top: 1px solid #787575;
    box-sizing: border-box;
    border-radius: 2px;
    margin-bottom: 0rem !important;
    .td_padding_null {
      padding: 0 !important;
    }
    th:not(.custom_width),
    td:not(.custom_width) {
      width: 90px;
    }
    th,
    td {
      border: 1px solid #787575;
      font-family: Roboto Condensed;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 12px;//16px;
      // width: fit-content;
      // min-width: 60px;
      word-break: break-word;
      vertical-align: middle;
      height: 20px;
      letter-spacing: 0.2px;
      color: #e8e8e8;
      padding: 5px;
      border-radius: 2px !important;
      &.td_padding_null {
        padding: 0px !important;
      }
      input {
        background-color: #535353;
      }
    }
    th {
      font-family: Roboto Condensed;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.2px;
      color: #e8e8e8;
      padding-left: 3px !important;
      padding-right: 0px !important;
      padding-top: 3px !important;
      padding-bottom: 3px !important;
    }
    .table-hover tbody tr:hover {
      background: transparent !important;
    }
  }
}
.custom_width {
  // width: auto !important;
}
// .vls-dc_machine-windings .border_none {
//   .table-vls-table_data-oscilloscope-table th,
//   .table-vls-table_data-oscilloscope-table td {
//     width: auto !important;
//   }
// }
.table-responsive {
  & td {
    // padding: 0.5rem 0.3rem 0 0.3rem !important;
    padding: 0 !important;
  }
  max-height: 73vh;
  &::-webkit-scrollbar {
    width: 10px !important;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #535353 !important;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #25282b !important;
    border: 1px solid #535353;
    box-sizing: border-box;
  }
  // arow
  &::-webkit-scrollbar-button:vertical:start:decrement {
    position: relative;
    width: 10px;
    background-size: contain;
    background-position-x: center;
    background-position-y: center;
    background-repeat: no-repeat;
    background-color: rgba($color: #535353, $alpha: 1);
    background-image: url("../../../../assets/images/arrowUp.png");
  }
  &::-webkit-scrollbar-button:vertical:end:increment {
    position: relative;
    width: 10px;
    background-size: contain;
    background-position-x: center;
    background-position-y: center;
    background-repeat: no-repeat;
    background-color: rgba($color: #535353, $alpha: 1);
    background-image: url("../../../../assets/images/arrowDown.png");
  }

  // &::-webkit-scrollbar-button:horizontal:start:decrement {
  //   background-position-x: center;
  //   background-position-y: center;
  //   background-repeat: no-repeat;
  //   background-image: url("../../../../assets/images/plus.png");
  // }

  // &::-webkit-scrollbar-button:horizontal:end:increment {
  //   background-position-x: center;
  //   background-position-y: center;
  //   background-repeat: no-repeat;
  //   background-image: url("../../../../assets/images/plus.png");
  // }
  // /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #535353 !important;
  }
}
.table thead tr th,
.table th {
  border-top: none !important;
  cursor: pointer;
  border-bottom: 2px solid rgba($color: #fff, $alpha: 0.1);
}
.table-hover tbody tr:hover {
  background: rgba(255, 255, 255, 0.1) !important;
}
.table td {
  font-size: 16px;
  padding: 0.75rem;
  vertical-align: top;
  color: #fff;
  cursor: pointer;
  border-top: 1px solid rgba($color: #fff, $alpha: 0.1) !important;
  @include vg {
    font-size: 14px;
  }
}
