.news {
  &-header {
    &-title {
      width: 100%;
      margin-top: 50px;
      font-family: Montserrat;
      font-style: normal;
      font-weight: bold;
      font-size: 46px;
      line-height: 132%;
      letter-spacing: 0.2px;
      color: #25282b;
    }
    &-menu {
      margin-top: 30px;
      margin-bottom: 20px;
      ul {
        display: flex;
        padding: 0;
        margin: 0;
        overflow: hidden;
        overflow-x: auto;
        li {
          cursor: pointer;
          list-style: none;
          margin-left: 10px;
          padding: 12px;
          font-family: Montserrat;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 16px;
          align-items: center;
          text-align: center;
          letter-spacing: 0.5px;
          color: #25282b;
          &:first-child {
            margin-left: 0px;
          }
          &.active {
            background: #f2f3f7;
            border-radius: 8px;
          }
          &:hover {
            background: #f2f3f7;
            border-radius: 8px;
          }
        }
      }
    }
  }
  &-content {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: stretch;
    grid-gap: 30px;
    @include md {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 15px;

    }
    @include xs {
      grid-template-columns: 1fr;
    }
    &-paginations {
      margin-top: 50px;
      margin-bottom: 75px;
      @include xs {
        margin-top: 30px;
        margin-bottom: 45px;
      }
    }
    a {
      &:hover {
        text-decoration: none;
      }
    }
    &-wrap_news_item {
      min-height: 390px;
      border: 1px solid #e8e8e8;
      box-sizing: border-box;
      border-radius: 8px;
      background: #fff;
      transition: .3s;
      @include mob {
        min-height: 280px;
      }
      &:hover {
        background: linear-gradient(357.48deg, #F2F3F7 1.22%, rgba(242, 243, 247, 0) 38.59%);
      }
      &-wrap_img {
        width: 100%;
        height: 180px;
        background-repeat: no-repeat;
        background-color: rgba(242, 243, 247, 0.5);
        /* opacity: 0.2; */
        overflow: hidden;
        border-radius: 4px 4px 0px 0px;
        @include mob {
          height: 145px;
          overflow: hidden;
        }
      }
      &-img {
        width: 100%;
        height: 100%;
        background-size: cover;
        background-repeat: no-repeat;
      }
      &-content {
        padding: 1rem 1rem;
      }
      &-type {
        margin-bottom: 1rem;
        left: 0px;
        display: inline-block;
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        align-items: center;
        text-align: center;
        letter-spacing: 0.2px;
        padding: 6px 6px;
        line-height: 10px;
        @include xxs {
          margin-top: 10px;
        }
        &.energy {
          color: $EnergyNews;
          background: rgba($color: $EnergyNews, $alpha: 0.07);
          border-radius: 4px;
        }
        &.achievements {
          color: $Achievements;
          background: rgba($color: $Achievements, $alpha: 0.07);
          border-radius: 4px;
        }
        &.government {
          color: $Government;
          background: rgba($color: $Government, $alpha: 0.07);
          border-radius: 4px;
        }
        &.industry {
          color: $Industry;
          background: rgba($color: $Industry, $alpha: 0.07);
          border-radius: 4px;
        }
      }
      &-text {
        width: 100%;
        height: 167px;
        overflow: hidden;
        overflow-y: auto;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: 0.2px;
        color: #25282b;
        word-wrap: break-word;
        @include lg {
          font-size: 1rem;
        }
        @include sm {
          font-size: 0.9rem !important;
        }
        @include xs {
          height: 100px;
        }
        &::-webkit-scrollbar {
          width: 5px !important;
        }

        /* Track */
        &::-webkit-scrollbar-track {
          background: #f1f1f1 !important;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: #d8d8d8 !important;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: #555 !important;
        }
        @include xxs {
          font-size: 14px;
        }
      }
      &-date {
        margin-top: 1rem;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 140%;
        letter-spacing: 0.1px;
        color: $GrayForAddText;

        @include xxs {
          font-size: 13px;
        }
      }
    }
  }
}
