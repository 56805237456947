.row {
  margin-right: 0 !important;
  margin-left: 0 !important;
}
.margin-def {
  margin-right: 0 !important;
  margin-left: 0 !important;
}
.col-canvas {
  height: 100%;
}
.row-canvas {
  height: 95%;
  width: 100%;
  position: absolute;
  overflow: hidden;
}
.canvas-wrapper {
  position: absolute !important;
  /* left: 300px; */
  left: 50px;
}
.component {
  position: absolute;
  height: 95%;
  width: 100%;
  &-vls__wraper {
    position: relative;
    height: 90%;
    width: 100%;
  }

  &-main__block_scheme_wraper {
    position: relative;
    width: 100%;
    height: 85%;
    background-color: #d5d5ec;
    overflow: hidden;

    &-map_sheme {
      position: relative;
      width: 100%;
      height: 100%;
      //background: url();
      overflow: hidden;
    }

    &-elment_to_map {
      position: relative;
      width: 10%;
      height: 10%;
      background-color: #b7c2ec;

      &:active {
        border: 2px solid red;
      }
    }
  }

  &-panel__block_scheme_wraper {
    position: relative;
    width: 100%;
    height: 15%;
    background-color: #eeeeee;
    display: flex;
  }

  &-filters__panel_bs {
    position: relative;
    width: 50%;
    height: 100%;
  }

  &-elements__panel_bs {
    position: relative;
    width: 50%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;

    &-svg__icon {
      position: relative;
      cursor: pointer;
      width: 50px;
      height: 50px;
      align-content: stretch;
      margin: 0px 0px 0px 7px;
      padding: 6px;
      //&-img{
      //  padding: 5px;
      //}
      &.active {
        color: #fff;
        border-radius: 6px;
        background-color: #3787ff;
      }
      &:hover {
        color: #fff;
        border-radius: 6px;
        background-color: #9dc4ff;
      }
    }
  }
}

.canvas {
  overflow-y: hidden;
  &-wraper {
    position: relative;
    z-index: 0;
    // width: 100%;
    // height: 1160px; //900px; 
    //Todo: надо сделать так чтоб он определял процентно а не захардкоженое число иначе появятся страшные полосы прокрутки
  }
}

.base_fields {
  &-wraper_all {
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 0px !important;
  }

  &-wraper__form_items {
    width: 95px !important;
  }

  &-wraper_button {
    position: absolute;
    padding: 40px;
    bottom: 0;
    margin-bottom: 30px !important;
  }

  &-wraper_all_filters {
    margin-bottom: 0 !important;
    padding-left: 15px;
  }

  &-wraper__filter {
    width: 25% !important;
    margin-left: 10px;
    &-select {
      width: 30% !important;
      margin-left: 10px;
    }
  }
}
.canvas-charts_generate {
  position: absolute;
  z-index: -200;
  width: 621px;
  height: 354px;
  visibility: hidden;
}
