.tool_panel {
  &-wrap {
    position: relative;
    width: 100%;
    height: 100%;
  }
  &-controler {
    position: relative;
    width: 100%;
    height: auto;
    min-height: 30px;
    margin-top: 10px;
    &-line {
      position: relative;
      background: rgba(255, 255, 255, 0.1);
      width: 100%;
      height: 2px;
    }
    &-processing {
      position: relative;
      width: 36px;
      height: 36px;
      margin: 0 auto;
      margin-top: 10px;
      background: #535353;
      box-shadow: -2px -2px 4px #5f5f5f, 2px 2px 4px #474747;
      border-radius: 2px;
      background-image: url("../../../../assets/images/settings.png");
      background-repeat: no-repeat;
      background-size: 20px 20px;
      background-position: center;
      cursor: pointer;
    }
    &-on_and_off_btn {
      position: relative;
      width: 36px;
      height: 60px;
      margin: 0 auto;
      margin-top: 10px;
      background: #535353;
      box-shadow: -2px -2px 4px #5f5f5f, 2px 2px 4px #474747;
      border-radius: 2px;
      background-repeat: no-repeat;
      background-size: 30px 52px;
      background-position: center;
      cursor: pointer;
      &.on {
        background-image: url("../../../../assets/images/on.svg");
      }
      &.off {
        background-image: url("../../../../assets/images/off.svg");
      }
    }
    &-pause_btn {
      position: relative;
      width: 36px;
      height: 36px;
      margin: 0 auto;
      margin-top: 10px;
      background: #535353;
      box-shadow: -2px -2px 4px #5f5f5f, 2px 2px 4px #474747;
      border-radius: 2px;
      background-repeat: no-repeat;
      background-size: 12px 14px;
      background-position: center;
      cursor: pointer;
      &.pause {
        background-image: url("../../../../assets/images/pause.svg");
      }
      &.play {
        background-image: url("../../../../assets/images/play.svg");
      }
    }
  }
  &-elements {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    &-btn {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 36px;
      height: 36px;
      outline: none;
      border: none;
      background: none;
      padding: 0px 0px !important;
      &:active,
      &:focus,
      &:hover {
        outline: none;
        box-shadow: 2px 2px 4px #474747, -2px -2px 4px #5F5F5F;
        border-radius: 1px;
      }
      &:active {
        .tool_panel-elements-icon {
          opacity: 1;
        }
      }
    }
    &-image {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 26px;
      height: 26px;
    }
    
    &-icon {
      position: relative;
      width: 26px;
      height: 26px;
      opacity: 0.7;
    }
  }
  &-add_element_icon {
    position: absolute;
    width: 3px;
    height: 3px;
    display: block;
    bottom: 0;
    right: 0;
    z-index: 10;
  }
  &-add_element_select {
    position: absolute;
    width: -moz-fit-content;
    width: fit-content;
    height: max-content;
    top: 0;
    left: 50px;
    background: #37393a;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.2px;
    color: #ffffff;
    border-left: 1px solid $BlueEmpty;
    z-index: 10;
    &-text {
      position: relative;
      white-space: nowrap;
      height: 30px;
      line-height: 20px;
      padding: 5px 5px;
      cursor: pointer;
      &:hover {
        color: $BlueEmpty;
      }
    }
    &-key_code {
      position: relative;
      right: 0px;
      margin-left: 10px;
    }
    &.active {
      display: block;
    }
    &.deactive {
      display: none;
    }
  }
}
