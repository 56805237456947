$gr: #455861;
$gren: #90c846;
$primaryColor: #bada55;
$colorForSidebarText: #e8e8e8;
$fontRoboto: Roboto;
$fontRobotoCondensed: Roboto Condensed;
$fontMonserat: Montserrat;
$w: #fff;
$blueLinkText: #007eff;
$blueText: #4279f1;
$black: #25282b;
$subTextColor: #2a323a;
$textForSidebarWhiteTheme: #52575c;
$borderWhiteTheme: #d3d5dc;
$folderBlackHover: #16191d;
$darckTextForModal: #25282b;
$lightThemeTextForSidebar: #52575c;
$darckThemeHeader: #2e343a;
$lightThemeBody: #fdfdff;
$lightThemeHeader: #e2e4ec;
$lightThemeBlocks: #f9f9fa;
$lightThemeInput: #f2f3f7;
$darckThemeBody: #1f2429;
$DefaultGreen: #40dd7f;
$BlueEmpty: #4a9eea;
$yellowBtn: #ffa62f;
$EnergyNews: #2ac769;
$Achievements: #f6a609;
$Government: #4a9eea;
$Industry: #ff6262;
$GrayForAddText: #a1acbd;
$darkInput: #424242;
$backGroungTabWhite: #ebecf0;
$blueTooltip: #1a8bff;
$textColorForTooltip: #fbfbfb;
$textColorTitleSidebar: #cce5ff;
$borderForSidebar: rgba(255, 255, 255, 0.2);

$xxxs-width: 321.98px;
$xxs-width: 359px;
$ph-width: 414.98px;
$xs-width: 575.98px;
$mob-width: 600.98px;
$sm-width: 768.98px;
$md-width: 991.98px;
$vertSm-width: 1024.99px;
$lg-width: 1100.98px;
$nb-width: 1200px;
$nb2-width: 1280px;
$vg-width: 1399.98px;
$xl-width: 1469.98px;
$xxl-width: 1601.98px;
$xxxl-width: 1921.98px;

@mixin height-only {
  @media screen and (max-height: #{901px}) {
    @content;
  }
}
@mixin vertSm {
  @media screen and (max-width: #{$vertSm-width}) {
    @content;
  }
}

@mixin lg-only {
  @media screen and (min-width: #{$lg-width}) {
    @content;
  }
}
@mixin nb {
  @media screen and (max-width: #{$nb-width}) {
    @content;
  }
}
@mixin nb2 {
  @media screen and (max-width: #{$nb2-width}) {
    @content;
  }
}

@mixin xxxl {
  @media screen and (max-width: #{$xxxl-width}) {
    @content;
  }
}

@mixin xxl {
  @media screen and (max-width: #{$xxl-width}) {
    @content;
  }
}

@mixin xl {
  @media screen and (max-width: #{$xl-width}) {
    @content;
  }
}

@mixin vg {
  @media screen and (max-width: #{$vg-width}) {
    @content;
  }
}

@mixin lg {
  @media screen and (max-width: #{$lg-width}) {
    @content;
  }
}

@mixin md {
  @media screen and (max-width: #{$md-width}) {
    @content;
  }
}

@mixin sm {
  @media screen and (max-width: #{$sm-width}) {
    @content;
  }
}

@mixin mob {
  @media screen and (max-width: #{$mob-width}) {
    @content;
  }
}

@mixin xs {
  @media screen and (max-width: #{$xs-width}) {
    @content;
  }
}

@mixin xxs {
  @media screen and (max-width: #{$xxs-width}) {
    @content;
  }
}

@mixin xxxs {
  @media screen and (max-width: #{$xxxs-width}) {
    @content;
  }
  // @media screen and (max-width: #{$ph-width}) {
  //   @content;
  // }
}

@mixin ph {
  @media screen and (max-width: #{$ph-width}) {
    @content;
  }
}
@mixin row($mr) {
  display: flex;
  flex-wrap: wrap;
  margin-left: -#{$mr}px;
  margin-right: -#{$mr}px;
}

@mixin row-flex() {
  display: flex;
  flex-wrap: wrap;
}

@mixin col($colsize, $mrg) {
  width: calc(100% / 12 * #{$colsize} - #{$mrg * 2}px - 0.1px);
  margin-left: #{$mrg}px;
  margin-right: #{$mrg}px;
  word-wrap: break-word;
  flex-shrink: 1;
}

@mixin coloff($coloffset) {
  margin-left: calc(100% / 12 * #{$coloffset});
}

@mixin h($s) {
  @extend %heading;

  @if $s == 1 {
    font-size: 60px;

    @include xxl {
      font-size: 50px;
    }
    @include lg {
      font-size: 40px;
    }
    @include sm {
      font-size: 30px;
    }
  } @else if $s == 2 {
    font-size: 42px;

    @include xl {
    }
    @include sm {
    }
  } @else if $s == 3 {
    font-size: 30px;

    @include xl {
      font-size: 28px;
    }
    @include sm {
      font-size: 26px;
    }
  } @else if $s == 4 {
    font-size: 26px;

    @include xl {
      font-size: 24px;
    }
    @include sm {
      font-size: 22px;
    }
  } @else if $s == 5 {
    font-size: 20px;

    @include xl {
      font-size: 18px;
    }
  } @else if $s == 6 {
    font-size: 16px;

    @include sm {
    }
  } @else {
  }
}

@mixin flex($ff) {
  display: flex;

  @if $ff == lt {
    justify-content: flex-start;
    align-items: flex-start;
  } @else if $ff == lc {
    justify-content: flex-start;
    align-items: center;
  } @else if $ff == lb {
    justify-content: flex-start;
    align-items: flex-end;
  } @else if $ff == ct {
    justify-content: center;
    align-items: flex-start;
  } @else if $ff == cc {
    justify-content: center;
    align-items: center;
  } @else if $ff == cb {
    justify-content: center;
    align-items: flex-end;
  } @else if $ff == rt {
    justify-content: flex-end;
    align-items: flex-start;
  } @else if $ff == rc {
    justify-content: flex-end;
    align-items: center;
  } @else if $ff == rb {
    justify-content: flex-end;
    align-items: flex-end;
  } @else if $ff == st {
    justify-content: space-between;
    align-items: flex-start;
  } @else if $ff == sc {
    justify-content: space-between;
    align-items: center;
  } @else if $ff == sb {
    justify-content: space-between;
    align-items: flex-end;
  } @else if $ff == ls {
    justify-content: flex-start;
    align-items: stretch;
  } @else if $ff == cs {
    justify-content: center;
    align-items: stretch;
  } @else if $ff == rs {
    justify-content: flex-end;
    align-items: stretch;
  } @else if $ff == ss {
    justify-content: space-between;
    align-items: stretch;
  } @else if $ff == sn {
    justify-content: space-between;
    align-items: baseline;
  } @else {
  }
}

@mixin flexcol($fw) {
  @if $fw == w {
    flex-flow: column wrap;
  } @else if $fw == n {
    flex-flow: column nowrap;
  }
}

