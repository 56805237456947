.pfml {
  &-container {
    position: absolute;
    width: 100%;
    height: 100%;
    max-width: 100% !important;
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
  &-sidebar {
    &-dot {
      &-position_btn {
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.2px;
        color: #e8e8e8;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-left: 10px;
        span {
          padding-top: 5px;
        }
        .button__option-menu {
          display: block;
          width: 90px;
          height: 24px;
          font-family: Montserrat;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 12px;
          margin: 2px 10px;
          margin-top: 10px;
          &:disabled {
            color: #868686;
            background: #535353;
            border: 1px solid #868686;
            box-sizing: border-box;
            border-radius: 6px;
          }
          &:active,
          &:focus {
            outline: none;
          }
        }
      }
      &-input {
        width: 185px;
        max-width: 185px;
        height: 26px;
        position: relative;
        &.custom_first_items_offset {
          width: 230px;
          max-width: 239px;
        }
      }
    }
    &-researche_env {
      margin-top: 10px;
      margin-left: 10px;
      ul {
        padding-left: 0;
        li {
          list-style: none;
          font-family: $fontRoboto;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;
          letter-spacing: 0.2px;
          margin-top: 10px;
        }
      }
    }
    &-wrapper_custom_inp {
      &-input_one {
        background: #37393a;
        border: 1px solid rgba(255, 255, 255, 0.2);
        box-sizing: border-box;
        border-radius: 1px;
        height: 26px;
        width: 250px;
        font-family: Roboto Condensed;
        background: #424242;
        border: 1px solid rgba(255, 255, 255, 0.2);
        box-sizing: border-box;
        border-radius: 1px;
        color: #fff;
        max-width: calc(100% - 50px);
        padding-left: 7px;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        &:hover {
          background: #16191d;
        }
        &.double_lable {
          width: 190px;
        }
      }
      &-tree_inp {
        position: relative;
        margin: 7px;
        border: 1px solid #787575;
        box-sizing: border-box;
        border-radius: 2px;
        padding: 3px;

        .sidebar__value {
          display: flex;
          justify-content: space-between;
          margin: 5px auto;
          .sidebar__value__input {
            width: 30%;
            font-family: Roboto;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            letter-spacing: 0.2px;
            color: #e8e8e8;
            .wrapper__input {
              margin: 0;
              input {
                text-align: center;
              }
            }
          }
        }
      }
    }
    &-wrapper_custom_check_box {
      font-family: $fontRoboto;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      padding: 7px;
      .default_components-help_text {
        margin-bottom: 5px;
        top: 0px;
        font-family: $fontRobotoCondensed;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.2px;
        color: #e8e8e8;
      }
    }
  }
  &-row_header {
    align-items: center;
    height: 40px;
    background: $darckThemeHeader;
    border-bottom: 1px solid #353434;
    .col-12 {
      padding: 0px 0px 0px 0px !important;
      height: 40px;
    }
  }
  &-row_canvas {
    height: calc(100% - 40px);
    overflow: hidden;
  }
  &-header {
    &-block {
      @include flex(cc);
      flex-wrap: wrap;
      flex: 1;
      &:first-child {
        justify-content: flex-start;
      }
      &:last-child {
        justify-content: flex-end;
      }
    }
    &-logo {
      width: 50px;
      height: 40px;
      display: block;
      background-image: url("../../../../assets/images/logo.svg");
      background-repeat: no-repeat;
      background-size: 30px 30px;
      background-position: center;
    }
    &-menu-classic {
      position: relative;
      &::after,
      &::before {
        content: "";
        position: absolute;
        width: 1px;
        height: 20px;
        top: 50%;
        transform: translateY(-50%);
        background: rgba(255, 255, 255, 0.1);
      }
      &::after {
        right: 0;
      }
      &::before {
        left: 0;
      }
      ul {
        margin: 0;
        padding: 0;
        display: flex;
        
        li {
          list-style: none;
          font-family: $fontRoboto;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 39px;
          letter-spacing: 0.2px;
          color: rgba(255, 255, 255, 0.8);
          padding: 0 10px;
          opacity: 0.7;
          cursor: pointer;

          &:hover {
            background: #16191d;
          }
          &:focus {
            background: #16191d;
          }
        }
      }
    }
    &-project_info {
      &-text {
        font-size: 14px;
        line-height: 16px;
        text-align: right;
        letter-spacing: 0.2px;
        color: rgba(255, 255, 255, 0.5);
      }
      &-project_name {
        position: relative;
        font-family: 'Roboto', sans-serif;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.1px;
        color: #ffffff;
        cursor: pointer;
        margin-left: 5px;
        &::after {
          position: absolute;
          content: "";
          width: 8px;
          height: 5px;
          top: 50%;
          right: -15px;
          background-image: url("../../../../assets/images/arrowDownWhite.svg");
          background-repeat: no-repeat;
          background-size: 100%;
          background-position: center;
        }
      }
    }
    &-profile {
      @include flex(lc);
      &-link_avatar {
        @include flex(cc);
        position: relative;
        width: 30px;
        height: 30px;
        margin-right: 25px;
        border-radius: 30px;
        cursor: pointer;
        font-size: 20px;
        line-height: 16px;
        letter-spacing: 0.2px;
        text-decoration: none;
        background: $DefaultGreen;
        color: #ffffff;
        &:hover {
          text-decoration: none;
          color: #ffffff;
        }
      }
      &-will_share {
        &-button {
          padding: 6px 16px;
          font-family: Montserrat;
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          background: #4a9eea;
          border-radius: 4px;
          color: #fff;
          border: none;
          &:focus {
            outline: none;
          }
        }
      }
    }
    &-logout {
      width: 50px;
      height: 40px;
      padding: 0;
      margin-left: 15px;
      border: none;
      background: none;
      background-image: url("../../../../assets/images/logout.png");
      background-repeat: no-repeat;
      background-size: 20px 20px;
      background-position: center;
      &:focus,
      &:active {
        outline: none;
      }
    }
  }
  &-drop_down {
    // max-width: 356px;
    height: auto;
    font-family: $fontRoboto;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: $w;
    &-closed {
      position: absolute;
      margin: 0px 0px 0px 20px;
      cursor: pointer;
      top: 2%;
      right: 5%;
      width: 20px;
      height: 20px;
      display: inline-block;
      opacity: 0.5;
      background-image: url("../../../../assets/images/cansel.svg");
      background-repeat: no-repeat;
      background-size: 100%;
      color: #fff;
    }
    &__options {
      max-width: 365px;
      padding: 20px 10px 30px;
      .confirm_component-wrap {
        justify-content: center;
      }
    }
    &__view {
      max-width: 650px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      .confirm_component-wrap {
        justify-content: space-between;
      }
      &-left {
        border-right: 1px solid rgba(255, 255, 255, .1);
        .pfml-drop_down_wrap {
          margin-bottom: 0;
          .default_components {
            margin-bottom: 9px;
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
        .pfml-drop_down_block {
          padding: 10px;
          border-bottom: 1px solid rgba(255, 255, 255, .1);
          &:first-child {
            padding-top: 15px;
          }
          &:last-child {
            border: none;
          }
        }
      }
      &-right {
        .pfml-drop_down_block {
          padding: 15px;
          padding-bottom: 30px;
        }
      }
    }
    &_wrap {
      margin-bottom: 10px;
      
      &_confirm {
        margin-top: 30px;
      }
      .wrapper__input {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-shrink: 0;
        margin-left: 7px;
      }
      .wrapper__input label {
        font-family: $fontRoboto;
        font-weight: bold;
        font-size: 14px;
        line-height: 16px;
        flex-shrink: 0;
      }
      .input__data {
        max-width: 95px;
        &-wrapper {
          &::before {
            content: "\00a0=\00a0";
            font-family: $fontRoboto;
            font-weight: bold;
            font-size: 14px;
            line-height: 16px;
          }
        }
      }
      &-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .pfml-drop_down_wrap-title {
          margin-bottom: 0;
        }
      }
      &-title {
        margin-bottom: 5px;
        font-family: $fontRoboto;
        font-weight: bold;
        font-size: 14px;
        line-height: 16px;
      }
      &-form {
        &_group {
          padding: 5px;
          margin-bottom: 5px;
          background: #424242;
          border: 1px solid rgba(255, 255, 255, 0.2);
          border-radius: 1px;
        }
        &_label {
          width: 100%;
          display: flex;
          align-items: center;
          margin-bottom: 5px;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
      &_group {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 0 7px;
        padding: 5px;
        border: 1px solid rgba(255, 255, 255, 0.2);
        border-radius: 2px;
        .wrapper__input {
          margin: 0;
          label {
            font-weight: normal;
          }
        }
        .input__data {
          max-width: 106px;
        }
      }
    }
    
  }
  // background-color: #7a7a7a;
}
.col_pfml {
  position: relative;
  height: 100%;
  &-header-menu_left {
    width: 51px;
    background: #535353;
    box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
    border-right: 1px solid #353434;
  }
  &-canvas {
    width: calc(100% - 345px);
  }
  &-sidebar {
    overflow: hidden;
    width: 300px;
    height: calc(100% - 40px);
    right: 0px;
    position: absolute;
    background: #535353;
    border-left: 1px solid #353434;
    box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
  }
}

.pfml-drop-menu {
  background-repeat: no-repeat;
  background-position: center;
  background-size: 80%;
  width: 40px;
  height: 40px;
  position: absolute;
  top: 0;
  left: 5em;
  background-image: url("../../../../assets/images/open-menu-drop.svg");
}
.pfml-menu-active {
  width: 100%;
  position: absolute;
  left: 0;
  top: 40px;
  left: 0% !important;
  transform: translate(0px, 0px) !important;
  z-index: 1060 !important;
  display: block;
  max-width: 100% !important;
  width: 100%;
  height: calc(100% - 40px);
  overflow: hidden;
  background: transparent;
  opacity: 0.7;
}
.pfml-menu-hidden {
  display: none;
}
.pfml-menu-burger-custom_translate {
  border-radius: 0px !important;
  background-color: $darckThemeHeader !important;
  z-index: 1070;
}
.pfml-menu-classic-custom_translate {
  border-radius: 0px !important;
  background-color: $darckThemeHeader !important;
  z-index: 1070;
}
